export type TimerValues = {
  days: [number, number];
  hours: [number, number];
  minutes: [number, number];
  seconds: [number, number];
};

export const getTimerValues = (secondsRemaning: number): TimerValues => {
  const SECONDS_IN_DAY = 86400;
  const SECONDS_IN_HOUR = 3600;
  const SECONDS_IN_MINUTES = 60;

  const days = Math.floor(secondsRemaning / SECONDS_IN_DAY);
  const hours = Math.floor(secondsRemaning / SECONDS_IN_HOUR) - 24 * days;
  const minutes = Math.floor(secondsRemaning / SECONDS_IN_MINUTES) - 1440 * days - 60 * hours;
  const seconds = secondsRemaning % 60;

  return {
    days: [Math.floor(days / 10), days % 10],
    hours: [Math.floor(hours / 10), hours % 10],
    minutes: [Math.floor(minutes / 10), minutes % 10],
    seconds: [Math.floor(seconds / 10), seconds % 10],
  };
};
