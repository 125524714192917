import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useStore } from 'app/store';
import classNames from 'classnames';
import * as dateFns from 'date-fns';
import { animate, motion, useMotionTemplate, useMotionValue } from 'framer-motion';
import { isNil, random } from 'lodash';
import { observer } from 'mobx-react';

import { useToast } from '~features/toaster/lib';
import {
  type ApiError,
  FortunePrizeType,
  type GetPrizeInfoResponse,
  type GetPrizesListResponse,
  WidgetsFortuneApiService,
} from '~shared/api/client';
import { ArrowRightIcon } from '~shared/assets/icons/arrows';
import coinImage from '~shared/assets/images/_coin.png';
import tgStar from '~shared/assets/images/_tg-star.png';
import { FortuneSpinArrow } from '~shared/assets/images/fortune-spin';
import { isDefined } from '~shared/lib';
import { AnimatedCounter, Button, Hedgehog, Page, Spinner } from '~shared/ui';

import { WheelSvgElements } from './wheel-svg-elements.component';

import { FORTUNUE_PRIZE_TYPES_MAP, getPrizeInfo } from '../lib/fortune-prize-types-map.lib';

const CENTER_X = 202;
const CENTER_Y = 206;
const RADIUS = 180;

export const FortuneSpin: React.FC = observer(() => {
  const { toast } = useToast();
  const {
    projectStore: { getProject, project },
    userStore: { user, getUser, openPurchaseGameLivesDrawer },
  } = useStore();
  const [isSpinLoading, setIsSpinLoading] = useState(false);
  const wheelRotationValue = useMotionValue(-90);
  const wheelRotationTemplate = useMotionTemplate`rotate(${wheelRotationValue}deg)`;

  const [prizes, setPrizes] = useState<GetPrizesListResponse | null>(null);
  const [winningSpin, setWinningSpin] = useState<GetPrizeInfoResponse | null>(null);
  const [minutesRemaining, setMinutesRemaining] = useState(0);

  const timerRef = useRef<NodeJS.Timer>();

  const getWheelPrizes = useCallback(async () => {
    try {
      const prizesResponse = await WidgetsFortuneApiService.getPrizesListApiV2WidgetFortunePrizesGet();
      setPrizes(prizesResponse);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleSpin = useCallback(async () => {
    try {
      if (isNil(user) || isNil(user.id) || isNil(project) || isNil(project.id) || isNil(prizes)) return;

      setWinningSpin(null);
      setIsSpinLoading(true);
      const spinResponse = await WidgetsFortuneApiService.makeSpinApiV2WidgetUsersUserIdFortuneMakeSpinPost(user.id);

      const prizeIndex = prizes.results.findIndex((prize) => prize.id === spinResponse.prize?.id);
      const degreesPerSection = 360 / prizes.results.length;
      const prizeRotation = -360 + (prizes.results.length - prizeIndex - random(0.2, 0.8, true)) * degreesPerSection;
      const wheelDiff = -1080 - (wheelRotationValue.get() % 360);

      await animate(wheelRotationValue, wheelRotationValue.get() + wheelDiff + prizeRotation - 90, {
        duration: 5,
        ease: 'anticipate',
      });
      setWinningSpin(spinResponse.prize);
      await getUser();
      await getProject(project.id, user.id);
      const prizeInfo = getPrizeInfo(spinResponse.prize?.type ?? FortunePrizeType.FORTUNE_LIVES);
      toast({
        className: 'w-auto px-8 mx-auto',
        description: (
          <div className="flex items-center">
            <span className="whitespace-nowrap">You win</span>
            <div className="ml-3 flex items-center text-center text-md font-bold">
              {isDefined(prizeInfo.icon) && <img className="mr-1 size-6" src={prizeInfo.icon} alt="" />}+
              <span className="whitespace-nowrap">
                {parseFloat(spinResponse.prize?.reward)} {prizeInfo.currency}
              </span>
            </div>
          </div>
        ),
      });
    } catch (error) {
      toast({
        title:
          ((error as ApiError)?.body as { detail: { msg: string } })?.detail?.msg ??
          ((error as ApiError)?.body as { detail: Array<{ msg: string }> })?.detail?.[0]?.msg ??
          'Unknown error',
      });
    } finally {
      setIsSpinLoading(false);
    }
  }, [toast, user, project, getUser, getProject, wheelRotationValue, prizes]);

  const getArcPath = useCallback((startAngle: number, endAngle: number): string => {
    const startX = CENTER_X + RADIUS * Math.cos(startAngle);
    const startY = CENTER_Y + RADIUS * Math.sin(startAngle);
    const endX = CENTER_X + RADIUS * Math.cos(endAngle);
    const endY = CENTER_Y + RADIUS * Math.sin(endAngle);
    const largeArcFlag = endAngle - startAngle <= Math.PI ? '0' : '1';
    return `M${CENTER_X},${CENTER_Y} L${startX},${startY} A${RADIUS},${RADIUS} 0 ${largeArcFlag},1 ${endX},${endY} Z`;
  }, []);

  useEffect(() => {
    clearInterval(timerRef.current);

    const handleTimerUpdate = (): void => {
      const secondsRemaning = dateFns.differenceInSeconds(
        isDefined(prizes) ? new Date(prizes.next_lives_update_at * 1000) : new Date(),
        new Date()
      );

      setMinutesRemaining(Math.trunc(secondsRemaning / 60));
    };

    handleTimerUpdate();
    timerRef.current = setInterval(handleTimerUpdate, 30000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [user, prizes]);

  useEffect(() => {
    void getWheelPrizes();
  }, [getWheelPrizes]);

  return (
    <Page className="items-center" headerContent={<h1 className="text-lg font-bold uppercase">Spin</h1>}>
      <div className="flex flex-col items-center space-y-5 text-center">
        <h2 className="text-md">Your lives</h2>

        <div className="flex flex-col items-center space-y-2">
          <div className="flex items-center">
            <Hedgehog className="mr-2 size-12 shrink-0" color="green" showGlowing={false} />
            <span className="text-4xl font-bold">{user?.fortune_lives}</span>
          </div>

          <p className="relative flex h-5 items-center">
            Life replenishment in
            <AnimatedCounter className="ml-1" value={Math.floor(minutesRemaining / 60)} />h{' '}
            <AnimatedCounter className="ml-1" value={minutesRemaining % 60} />m
          </p>
        </div>

        <Button
          className="rounded-xl border border-white/10 px-4 py-2 text-md font-normal"
          variant="wrapper"
          size="wrapper"
          onClick={openPurchaseGameLivesDrawer}
        >
          <img className="mr-2 size-6" src={tgStar} alt="" />
          Add lives
          <ArrowRightIcon className="ml-2 size-6" />
        </Button>
      </div>

      <div className="mt-10 hidden w-full max-w-[320px] flex-col space-y-3">
        <div className="flex w-full items-center space-x-3">
          <span className="flex min-h-9 flex-1 items-center justify-center rounded-xl border border-badge-border bg-badge px-3 py-1 text-sm font-semibold">
            {parseFloat(user?.ton_balance ?? '0')} TON
          </span>
          <span className="flex min-h-9 flex-1 items-center justify-center rounded-xl border border-badge-border bg-badge px-3 py-1 text-sm font-semibold">
            {parseFloat(user?.not_balance ?? '0')} NOT
          </span>
          <div className="flex min-h-9 flex-1 items-center justify-center rounded-xl border border-badge-border bg-badge px-3 py-1 text-sm font-semibold">
            <img className="mr-2 size-6" src={coinImage} alt="" />
            <span>{project?.balance}</span>
          </div>
        </div>

        <Button
          className="min-h-9 w-full rounded-xl border border-white/10 bg-white/5 p-1"
          variant="wrapper"
          size="wrapper"
          disabled
        >
          Withdraw
        </Button>
      </div>

      <div className="relative mt-[80px]">
        <svg
          className="h-auto w-full"
          width="405"
          height="412"
          viewBox="0 0 405 412"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <motion.g style={{ originX: '50%', originY: '50%', transform: wheelRotationTemplate }}>
            {prizes?.results.map((prize, index) => {
              const startAngle = (2 * Math.PI * index) / prizes.results.length;
              const endAngle = (2 * Math.PI * (index + 1)) / prizes.results.length;
              const path = getArcPath(startAngle, endAngle);

              const angle = startAngle + (endAngle - startAngle) / 2;
              const textX = CENTER_X + (RADIUS / 1.5) * Math.cos(angle);
              const textY = CENTER_Y + (RADIUS / 1.5) * Math.sin(angle);
              const rotation = (angle * 180) / Math.PI;

              const prizeInfo = getPrizeInfo(prize.type);

              const colors = Object.values(FORTUNUE_PRIZE_TYPES_MAP).map((infoItem) => infoItem.color);

              return (
                <g key={prize.id}>
                  <path
                    className={classNames({ 'animate-pulse': prize.id === winningSpin?.id })}
                    d={path}
                    fill={colors[index % colors.length]}
                    stroke="#1B1C2B"
                    strokeWidth="4"
                  />

                  <g transform={`rotate(${rotation}, ${textX}, ${textY})`}>
                    {isDefined(prizeInfo.icon) && (
                      <image
                        href={prizeInfo.icon}
                        x={textX - 14 * parseFloat(prize.reward).toString().length * 0.5 - 24}
                        y={textY - 14}
                        width="24"
                        height="24"
                        origin="center"
                      />
                    )}
                    <text
                      x={textX}
                      y={textY}
                      fill="#fff"
                      fontSize="20"
                      fontWeight="700"
                      fontFamily="Inter"
                      textAnchor="middle"
                      alignmentBaseline="middle"
                    >
                      {parseFloat(prize.reward)}
                      {isDefined(prizeInfo.currency) && ` ${prizeInfo.currency}`}
                    </text>
                  </g>
                </g>
              );
            })}
          </motion.g>

          <WheelSvgElements />
        </svg>

        <Button
          variant="wrapper"
          size="wrapper"
          className="absolute left-1/2 top-1/2 size-[12.5%] -translate-x-1/2 -translate-y-1/2 text-sm font-bold uppercase text-black disabled:opacity-100 max-[400px]:text-[3vw]"
          onClick={isDefined(user) && user.fortune_lives > 0 ? handleSpin : openPurchaseGameLivesDrawer}
          disabled={isSpinLoading}
        >
          <FortuneSpinArrow className="absolute bottom-0 left-0 h-auto w-full" />
          {isSpinLoading ? <Spinner className="w-4" /> : <span className="z-10">Push</span>}
        </Button>
      </div>
    </Page>
  );
});
