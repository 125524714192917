import React from 'react';

import { useStore } from 'app/store';
import { observer } from 'mobx-react';
import { twMerge } from 'tailwind-merge';

import { type PaymentProductSchema } from '~shared/api/client';
import { TelegramStarIcon } from '~shared/assets/icons';
import { farmingBoostHedgehogImage } from '~shared/assets/images/hedgehogs';
import { AnimatedCounter, Button } from '~shared/ui';

import { usePurchaseBoost, useActiveBoostTimer } from '../lib';

interface FarmingBoostCardProps {
  boost: PaymentProductSchema;
  className?: string;
}

export const FarmingBoostCard: React.FC<FarmingBoostCardProps> = observer(({ boost, className }) => {
  const {
    projectStore: { project },
  } = useStore();

  const { handlePurchaseBoost, isBoostLoading, isBoostsDisabled } = usePurchaseBoost(boost);
  const { isActive, timerValues } = useActiveBoostTimer(
    boost,
    project?.farming_up_end_period,
    project?.farming_up_purchased_id
  );

  return (
    <div className={twMerge('flex flex-col rounded-lg bg-white/10 backdrop-blur-lg', className)}>
      <div className="relative flex flex-col items-center p-2">
        <img className="h-auto w-full max-w-[113px]" src={farmingBoostHedgehogImage} alt="" />
        <span className="absolute left-0 top-0 rounded-br-lg rounded-tl-lg bg-green p-2 text-md font-bold text-background">
          X2
        </span>
        <div className="absolute bottom-[-15px] left-1/2 -translate-x-1/2 rounded-xl border border-white/10 bg-[#343442] px-3 py-1 text-sm font-semibold">
          {boost.days} days
        </div>
      </div>

      <hr className="w-full border-t-white/10" />

      <div className="flex flex-col items-center p-3">
        <p className="mb-auto mt-5 text-center font-bold">{boost.title}</p>

        {isActive ? (
          <Button className="mt-5 w-full max-w-[200px] p-2 text-base font-normal" buttonColor="green">
            <span className="flex h-[18px] items-center text-white/80">
              left <AnimatedCounter className="ml-1 font-bold text-white" value={timerValues.days} />d{' '}
              <AnimatedCounter className="ml-1 font-bold text-white" value={timerValues.hours} />
              h <AnimatedCounter className="ml-1 font-bold text-white" value={timerValues.minutes} />
              min
            </span>
          </Button>
        ) : (
          <Button
            className="mt-5 w-full max-w-[164px] p-2 text-base font-bold"
            buttonColor="blue"
            onClick={handlePurchaseBoost}
            isLoading={isBoostLoading}
            disabled={isBoostsDisabled}
          >
            <TelegramStarIcon className="mr-2 size-[22px]" />
            <span>{boost.price}</span>
          </Button>
        )}
      </div>
    </div>
  );
});
