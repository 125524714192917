import React, { useEffect, useRef } from 'react';

import { type MotionValue, motion, useTransform, AnimatePresence, useMotionValue, animate } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

export interface AnimatedCounterProps {
  value: string | number;
  className?: string;
}

const AnimatedCounter: React.FC<AnimatedCounterProps> = React.memo(({ className, value }) => {
  const parsedValue = typeof value === 'number' ? value.toString() : value;

  return (
    <div className={twMerge('inline-flex h-full overflow-hidden', className)}>
      <AnimatePresence>
        {parsedValue.split('').map((digit, index) =>
          isNaN(parseInt(digit)) ? (
            <motion.span
              className="flex h-full items-center justify-center"
              key={index}
              variants={{ show: { opacity: 1, width: 'auto' }, hide: { opacity: 0, width: '0px' } }}
              animate="show"
              exit="hide"
              initial="hide"
            >
              {digit}
            </motion.span>
          ) : (
            <Digit value={parseInt(digit)} key={index} delay={(parsedValue.length - 1 - index) * 0.2} />
          )
        )}
      </AnimatePresence>
    </div>
  );
});
AnimatedCounter.displayName = 'AnimatedCounter';

interface DigitProps {
  value: number;
  delay?: number;
}

const Digit: React.FC<DigitProps> = ({ value, delay }) => {
  const animatedValue: MotionValue<number> = useMotionValue(0);

  useEffect(() => {
    void animate(animatedValue, value, { duration: 1, delay: delay ?? 0 });
  }, [animatedValue, value, delay]);

  return (
    <motion.div
      className="relative h-full w-[1ch] tabular-nums"
      variants={{ show: { opacity: 1, width: '1ch' }, hide: { opacity: 0, width: '0px' } }}
      animate="show"
      exit="hide"
      initial="hide"
    >
      {[...Array(10).keys()].map((i) => (
        <Number key={i} mv={animatedValue} number={i} />
      ))}
    </motion.div>
  );
};

interface NumberProps {
  mv: MotionValue<number>;
  number: number;
}

const Number: React.FC<NumberProps> = ({ mv, number }) => {
  const ref = useRef<HTMLSpanElement | null>(null);

  const y = useTransform(mv, (latest) => {
    const offset = (10 + number - latest) % 10;

    let memo = offset * (ref.current?.clientHeight ?? 0);

    if (offset > 5) {
      memo -= 10 * (ref.current?.clientHeight ?? 0);
    }

    return memo;
  });

  const opacity = useTransform(mv, (latest) => {
    return Math.max(1 - Math.abs(latest - number), 0);
  });

  return (
    <motion.span style={{ y, opacity }} className="absolute inset-0 flex items-center justify-center" ref={ref}>
      {number}
    </motion.span>
  );
};

export { AnimatedCounter };
