export enum TaskTabType {
  Active = 'active',
  Static = 'static',
  Invitee = 'invitee',
}

export const TaskTabs = [
  {
    type: TaskTabType.Active,
    label: 'Top',
  },
  {
    type: TaskTabType.Static,
    label: 'Regular',
  },
  {
    type: TaskTabType.Invitee,
    label: 'Friends',
  },
];
