import React from 'react';

export const WheelSvgElements: React.FC = () => {
  return (
    <>
      <rect x="161" y="165" width="81.7187" height="81.719" rx="40.8594" fill="#1B1C2A" />
      <circle cx="202.5" cy="205.5" r="178.13" stroke="#141520" strokeWidth="22.74" />
      <circle cx="204.535" cy="206.016" r="188.5" stroke="white" strokeOpacity="0.08" strokeWidth="2" />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M386.388 207.868C393.995 200.261 399.002 192.935 397.571 191.504C396.14 190.074 388.814 195.081 381.207 202.687C373.6 210.294 368.593 217.621 370.024 219.051C371.454 220.482 378.781 215.475 386.388 207.868Z"
        fill="url(#paint0_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M401.971 223.45C403.401 222.02 396.424 212.725 386.388 202.689C376.351 192.653 367.056 185.676 365.626 187.106C364.196 188.536 371.173 197.831 381.209 207.867C391.245 217.904 400.541 224.88 401.971 223.45Z"
        fill="url(#paint1_radial_2235_1765)"
      />
      <path
        d="M383.474 211.243C386.452 211.243 388.866 208.642 388.866 205.434C388.866 202.226 386.452 199.625 383.474 199.625C380.496 199.625 378.082 202.226 378.082 205.434C378.082 208.642 380.496 211.243 383.474 211.243Z"
        fill="#333333"
      />
      <path
        d="M383.474 210.409C386.452 210.409 388.866 207.995 388.866 205.017C388.866 202.039 386.452 199.625 383.474 199.625C380.496 199.625 378.082 202.039 378.082 205.017C378.082 207.995 380.496 210.409 383.474 210.409Z"
        fill="white"
      />
      <path
        d="M383.476 209.38C385.585 209.38 387.295 207.548 387.295 205.288C387.295 203.028 385.585 201.195 383.476 201.195C381.366 201.195 379.656 203.028 379.656 205.288C379.656 207.548 381.366 209.38 383.476 209.38Z"
        fill="#333333"
      />
      <path
        d="M383.474 208.834C385.583 208.834 387.293 207.124 387.293 205.015C387.293 202.905 385.583 201.195 383.474 201.195C381.364 201.195 379.654 202.905 379.654 205.015C379.654 207.124 381.364 208.834 383.474 208.834Z"
        fill="#246FF2"
      />
      <path
        d="M385.599 204.978C385.877 203.806 385.152 202.63 383.98 202.352C382.808 202.074 381.632 202.799 381.353 203.971C381.075 205.143 381.8 206.319 382.972 206.598C384.145 206.876 385.321 206.151 385.599 204.978Z"
        fill="white"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M392.363 213.844C397.196 209.011 397.196 201.176 392.363 196.344C387.531 191.511 379.696 191.511 374.863 196.344C370.031 201.176 370.031 209.011 374.863 213.844C379.696 218.676 387.531 218.676 392.363 213.844Z"
        fill="url(#paint2_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M392.363 213.844C397.196 209.011 397.196 201.176 392.363 196.344C387.531 191.511 379.696 191.511 374.863 196.344C370.031 201.176 370.031 209.011 374.863 213.844C379.696 218.676 387.531 218.676 392.363 213.844Z"
        fill="url(#paint3_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M383.614 206.594C391.575 206.594 398.029 205.924 398.029 205.098C398.029 204.271 391.575 203.602 383.614 203.602C375.653 203.602 369.199 204.271 369.199 205.098C369.199 205.924 375.653 206.594 383.614 206.594Z"
        fill="url(#paint4_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M383.614 219.509C384.44 219.509 385.11 213.056 385.11 205.095C385.11 197.133 384.44 190.68 383.614 190.68C382.787 190.68 382.117 197.133 382.117 205.095C382.117 213.056 382.787 219.509 383.614 219.509Z"
        fill="url(#paint5_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M333.763 334.953C344.52 335.053 353.256 333.494 353.275 331.471C353.294 329.448 344.589 327.727 333.831 327.627C323.074 327.526 314.338 329.085 314.319 331.108C314.301 333.131 323.006 334.853 333.763 334.953Z"
        fill="url(#paint6_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M333.558 356.985C335.581 357.004 337.327 345.514 337.459 331.321C337.591 317.128 336.059 305.608 334.037 305.589C332.014 305.57 330.268 317.06 330.136 331.253C330.004 345.446 331.536 356.966 333.558 356.985Z"
        fill="url(#paint7_radial_2235_1765)"
      />
      <path
        d="M329.292 335.237C331.378 337.362 334.925 337.263 337.215 335.015C339.505 332.768 339.67 329.223 337.584 327.098C335.498 324.973 331.951 325.072 329.661 327.319C327.371 329.567 327.206 333.111 329.292 335.237Z"
        fill="#333333"
      />
      <path
        d="M329.888 334.652C331.974 336.777 335.388 336.809 337.513 334.723C339.638 332.637 339.67 329.223 337.584 327.098C335.498 324.973 332.084 324.941 329.959 327.027C327.833 329.113 327.802 332.527 329.888 334.652Z"
        fill="white"
      />
      <path
        d="M330.621 333.936C332.099 335.441 334.605 335.378 336.218 333.795C337.831 332.212 337.94 329.708 336.463 328.202C334.985 326.697 332.479 326.76 330.866 328.343C329.253 329.927 329.144 332.431 330.621 333.936Z"
        fill="#333333"
      />
      <path
        d="M331.009 333.554C332.487 335.059 334.905 335.082 336.411 333.604C337.916 332.126 337.938 329.708 336.461 328.202C334.983 326.697 332.565 326.674 331.059 328.152C329.554 329.63 329.531 332.048 331.009 333.554Z"
        fill="#246FF2"
      />
      <path
        d="M335.254 332.358C336.285 331.736 336.617 330.394 335.994 329.363C335.371 328.331 334.03 328 332.999 328.622C331.967 329.245 331.636 330.586 332.258 331.618C332.881 332.649 334.222 332.981 335.254 332.358Z"
        fill="white"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M333.665 343.402C340.499 343.465 346.09 337.977 346.154 331.143C346.218 324.309 340.729 318.718 333.895 318.654C327.061 318.591 321.47 324.079 321.406 330.913C321.343 337.747 326.831 343.338 333.665 343.402Z"
        fill="url(#paint8_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M333.665 343.402C340.499 343.465 346.09 337.977 346.154 331.143C346.218 324.309 340.729 318.718 333.895 318.654C327.061 318.591 321.47 324.079 321.406 330.913C321.343 337.747 326.831 343.338 333.665 343.402Z"
        fill="url(#paint9_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M332.712 332.079C338.289 337.76 343.288 341.897 343.877 341.318C344.467 340.739 340.424 335.664 334.848 329.983C329.271 324.301 324.272 320.165 323.682 320.743C323.092 321.322 327.135 326.397 332.712 332.079Z"
        fill="url(#paint10_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M323.491 341.122C324.07 341.712 329.145 337.669 334.827 332.093C340.508 326.516 344.645 321.517 344.066 320.927C343.487 320.337 338.412 324.38 332.73 329.957C327.049 335.534 322.912 340.533 323.491 341.122Z"
        fill="url(#paint11_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M207.868 385.042C211.307 374.848 212.541 366.061 210.624 365.414C208.707 364.767 204.365 372.506 200.926 382.7C197.487 392.893 196.253 401.681 198.17 402.327C200.087 402.974 204.429 395.235 207.868 385.042Z"
        fill="url(#paint12_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M228.747 392.086C229.394 390.17 219.015 384.938 205.567 380.401C192.118 375.864 180.692 373.739 180.046 375.655C179.399 377.572 189.777 382.803 203.226 387.34C216.674 391.878 228.101 394.002 228.747 392.086Z"
        fill="url(#paint13_radial_2235_1765)"
      />
      <path
        d="M206.753 389.36C209.422 388.038 210.43 384.636 209.006 381.761C207.581 378.887 204.263 377.628 201.595 378.95C198.927 380.272 197.918 383.674 199.343 386.549C200.767 389.424 204.085 390.682 206.753 389.36Z"
        fill="#333333"
      />
      <path
        d="M206.383 388.613C209.051 387.291 210.142 384.056 208.82 381.388C207.498 378.719 204.263 377.628 201.595 378.95C198.927 380.272 197.835 383.507 199.157 386.175C200.48 388.844 203.714 389.935 206.383 388.613Z"
        fill="white"
      />
      <path
        d="M205.929 387.693C207.82 386.757 208.538 384.356 207.535 382.33C206.532 380.305 204.186 379.422 202.296 380.359C200.405 381.295 199.687 383.696 200.69 385.722C201.693 387.747 204.039 388.63 205.929 387.693Z"
        fill="#333333"
      />
      <path
        d="M205.685 387.204C207.575 386.267 208.348 383.976 207.412 382.086C206.475 380.196 204.184 379.422 202.294 380.359C200.403 381.295 199.63 383.587 200.567 385.477C201.503 387.367 203.795 388.14 205.685 387.204Z"
        fill="#246FF2"
      />
      <path
        d="M205.874 382.806C205.603 381.632 204.431 380.9 203.257 381.171C202.083 381.443 201.351 382.614 201.622 383.788C201.893 384.962 203.065 385.694 204.239 385.423C205.413 385.152 206.145 383.98 205.874 382.806Z"
        fill="white"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M215.875 387.741C218.059 381.266 214.581 374.245 208.105 372.06C201.63 369.876 194.609 373.354 192.425 379.83C190.24 386.305 193.718 393.326 200.194 395.51C206.669 397.695 213.69 394.217 215.875 387.741Z"
        fill="url(#paint14_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M215.875 387.741C218.059 381.266 214.581 374.245 208.105 372.06C201.63 369.876 194.609 373.354 192.425 379.83C190.24 386.305 193.718 393.326 200.194 395.51C206.669 397.695 213.69 394.217 215.875 387.741Z"
        fill="url(#paint15_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M204.813 385.126C211.947 381.591 217.432 378.126 217.066 377.385C216.699 376.645 210.618 378.91 203.485 382.444C196.351 385.979 190.866 389.444 191.233 390.185C191.6 390.925 197.68 388.66 204.813 385.126Z"
        fill="url(#paint16_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M210.548 396.707C211.289 396.341 209.024 390.26 205.489 383.127C201.955 375.993 198.489 370.508 197.749 370.875C197.008 371.242 199.273 377.322 202.808 384.455C206.342 391.589 209.808 397.074 210.548 396.707Z"
        fill="url(#paint17_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M75.3736 331.857C73.5774 342.464 73.7384 351.336 75.7331 351.674C77.7279 352.012 80.8011 343.687 82.5973 333.08C84.3935 322.473 84.2325 313.601 82.2378 313.263C80.243 312.926 77.1699 321.25 75.3736 331.857Z"
        fill="url(#paint18_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M53.6472 328.177C53.3095 330.171 64.3802 333.708 78.3743 336.078C92.3683 338.448 103.987 338.753 104.324 336.759C104.662 334.765 93.5911 331.227 79.597 328.858C65.603 326.488 53.9848 326.183 53.6472 328.177Z"
        fill="url(#paint19_radial_2235_1765)"
      />
      <path
        d="M75.7939 327.417C73.3662 329.141 72.9043 332.659 74.7623 335.275C76.6202 337.891 80.0945 338.613 82.5222 336.888C84.9499 335.164 85.4118 331.645 83.5538 329.03C81.6959 326.414 78.2216 325.692 75.7939 327.417Z"
        fill="#333333"
      />
      <path
        d="M76.277 328.097C73.8493 329.821 73.2793 333.187 75.0038 335.615C76.7284 338.043 80.0945 338.613 82.5222 336.888C84.9499 335.164 85.5199 331.798 83.7954 329.37C82.0708 326.942 78.7048 326.372 76.277 328.097Z"
        fill="white"
      />
      <path
        d="M76.8707 328.93C75.1509 330.151 74.8179 332.636 76.1268 334.478C77.4357 336.321 79.891 336.824 81.6107 335.603C83.3305 334.381 83.6635 331.897 82.3546 330.054C81.0456 328.212 78.5904 327.708 76.8707 328.93Z"
        fill="#333333"
      />
      <path
        d="M77.1888 329.375C75.469 330.597 75.0652 332.981 76.2868 334.701C77.5085 336.42 79.8929 336.824 81.6127 335.603C83.3324 334.381 83.7362 331.997 82.5146 330.277C81.293 328.557 78.9085 328.153 77.1888 329.375Z"
        fill="#246FF2"
      />
      <path
        d="M77.6919 333.753C78.144 334.87 79.416 335.409 80.5329 334.957C81.6498 334.505 82.1888 333.233 81.7366 332.116C81.2845 330.999 80.0125 330.46 78.8956 330.912C77.7787 331.365 77.2398 332.637 77.6919 333.753Z"
        fill="white"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M67.0404 330.443C65.8993 337.182 70.4367 343.569 77.1749 344.71C83.9131 345.851 90.3006 341.314 91.4417 334.576C92.5828 327.837 88.0454 321.45 81.3072 320.309C74.569 319.168 68.1815 323.705 67.0404 330.443Z"
        fill="url(#paint20_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M67.0404 330.443C65.8993 337.182 70.4367 343.569 77.1749 344.71C83.9131 345.851 90.3006 341.314 91.4417 334.576C92.5828 327.837 88.0454 321.45 81.3072 320.309C74.569 319.168 68.1815 323.705 67.0404 330.443Z"
        fill="url(#paint21_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M78.3746 331.291C71.8844 335.901 67.0109 340.185 67.4895 340.859C67.9681 341.532 73.6174 338.341 80.1077 333.731C86.598 329.12 91.4714 324.837 90.9928 324.163C90.5143 323.489 84.8649 326.681 78.3746 331.291Z"
        fill="url(#paint22_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M70.8949 320.754C70.2212 321.233 73.4125 326.882 78.0229 333.372C82.6333 339.862 86.917 344.736 87.5907 344.257C88.2644 343.779 85.0731 338.129 80.4627 331.639C75.8523 325.149 71.5687 320.275 70.8949 320.754Z"
        fill="url(#paint23_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M23.042 208.574C32.1898 214.235 40.4686 217.43 41.5332 215.709C42.5979 213.989 36.0452 208.005 26.8975 202.344C17.7497 196.683 9.47092 193.488 8.40627 195.209C7.34163 196.929 13.8943 202.913 23.042 208.574Z"
        fill="url(#paint24_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M11.4431 227.313C13.1628 228.377 20.6116 219.456 28.0806 207.387C35.5495 195.317 40.2102 184.671 38.4906 183.607C36.771 182.542 29.3222 191.464 21.8532 203.533C14.3843 215.602 9.72351 226.249 11.4431 227.313Z"
        fill="url(#paint25_radial_2235_1765)"
      />
      <path
        d="M19.0889 206.513C19.7714 209.411 22.8562 211.165 25.9791 210.43C29.102 209.695 31.0803 206.749 30.3978 203.85C29.7154 200.951 26.6305 199.198 23.5076 199.933C20.3848 200.668 18.4064 203.614 19.0889 206.513Z"
        fill="#333333"
      />
      <path
        d="M19.901 206.321C20.5834 209.22 23.4865 211.017 26.3851 210.334C29.2838 209.652 31.0803 206.749 30.3978 203.85C29.7154 200.951 26.8123 199.155 23.9137 199.837C21.015 200.52 19.2185 203.423 19.901 206.321Z"
        fill="white"
      />
      <path
        d="M20.9004 206.086C21.3838 208.139 23.5593 209.384 25.7593 208.866C27.9594 208.348 29.351 206.263 28.8676 204.21C28.3841 202.157 26.2087 200.912 24.0086 201.43C21.8085 201.948 20.4169 204.033 20.9004 206.086Z"
        fill="#333333"
      />
      <path
        d="M21.4318 205.961C21.9152 208.014 23.9717 209.287 26.025 208.803C28.0784 208.32 29.351 206.263 28.8676 204.21C28.3841 202.157 26.3276 200.884 24.2743 201.368C22.221 201.851 20.9483 203.907 21.4318 205.961Z"
        fill="#246FF2"
      />
      <path
        d="M25.676 207.141C26.881 207.143 27.8595 206.168 27.8616 204.963C27.8638 203.758 26.8887 202.779 25.6837 202.777C24.4788 202.775 23.5002 203.75 23.4981 204.955C23.496 206.16 24.4711 207.139 25.676 207.141Z"
        fill="white"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M18.5946 215.76C24.406 219.356 32.0324 217.561 35.6287 211.749C39.2251 205.938 37.4294 198.311 31.6181 194.715C25.8067 191.119 18.1803 192.914 14.584 198.726C10.9876 204.537 12.7833 212.163 18.5946 215.76Z"
        fill="url(#paint26_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M18.5946 215.76C24.406 219.356 32.0324 217.561 35.6287 211.749C39.2251 205.938 37.4294 198.311 31.6181 194.715C25.8067 191.119 18.1803 192.914 14.584 198.726C10.9876 204.537 12.7833 212.163 18.5946 215.76Z"
        fill="url(#paint27_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M23.6484 205.584C25.473 213.334 27.6041 219.462 28.4085 219.273C29.2129 219.083 28.3859 212.648 26.5614 204.898C24.7369 197.149 22.6057 191.021 21.8013 191.21C20.9969 191.4 21.8239 197.835 23.6484 205.584Z"
        fill="url(#paint28_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M11.0736 208.54C11.263 209.345 17.6985 208.518 25.4477 206.693C33.1969 204.869 39.3253 202.737 39.1359 201.933C38.9465 201.129 32.511 201.956 24.7618 203.78C17.0126 205.605 10.8842 207.736 11.0736 208.54Z"
        fill="url(#paint29_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M78.0596 79.5975C87.2074 85.2586 95.4862 88.4532 96.5508 86.7328C97.6155 85.0125 91.0628 79.0286 81.915 73.3675C72.7673 67.7064 64.4885 64.5118 63.4239 66.2322C62.3592 67.9526 68.9119 73.9364 78.0596 79.5975Z"
        fill="url(#paint30_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M66.4607 98.3363C68.1803 99.4005 75.6292 90.4792 83.0981 78.4101C90.5671 66.3409 95.2278 55.6942 93.5082 54.63C91.7886 53.5658 84.3397 62.4871 76.8708 74.5563C69.4018 86.6254 64.7411 97.2721 66.4607 98.3363Z"
        fill="url(#paint31_radial_2235_1765)"
      />
      <path
        d="M74.1065 77.5361C74.789 80.4348 77.8738 82.1885 80.9967 81.4533C84.1196 80.718 86.0979 77.7721 85.4154 74.8735C84.7329 71.9748 81.6481 70.2211 78.5252 70.9563C75.4023 71.6916 73.424 74.6375 74.1065 77.5361Z"
        fill="#333333"
      />
      <path
        d="M74.9185 77.3449C75.601 80.2436 78.5041 82.0402 81.4027 81.3577C84.3013 80.6752 86.0979 77.7721 85.4154 74.8735C84.7329 71.9748 81.8299 70.1783 78.9312 70.8607C76.0326 71.5432 74.2361 74.4463 74.9185 77.3449Z"
        fill="white"
      />
      <path
        d="M75.918 77.1094C76.4014 79.1627 78.5769 80.4074 80.7769 79.8894C82.977 79.3714 84.3686 77.2869 83.8851 75.2335C83.4017 73.1802 81.2263 71.9355 79.0262 72.4535C76.8261 72.9715 75.4345 75.056 75.918 77.1094Z"
        fill="#333333"
      />
      <path
        d="M76.4494 76.9843C76.9328 79.0376 78.9893 80.3102 81.0426 79.8268C83.0959 79.3433 84.3686 77.2869 83.8851 75.2335C83.4017 73.1802 81.3452 71.9075 79.2919 72.391C77.2386 72.8744 75.9659 74.9309 76.4494 76.9843Z"
        fill="#246FF2"
      />
      <path
        d="M80.6936 78.1643C81.8986 78.1664 82.8771 77.1913 82.8792 75.9864C82.8813 74.7814 81.9063 73.8029 80.7013 73.8007C79.4964 73.7986 78.5178 74.7737 78.5157 75.9787C78.5136 77.1836 79.4886 78.1622 80.6936 78.1643Z"
        fill="white"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M73.6122 86.7832C79.4235 90.3796 87.05 88.584 90.6463 82.7726C94.2426 76.9612 92.447 69.3347 86.6357 65.7384C80.8243 62.142 73.1979 63.9376 69.6015 69.749C66.0052 75.5604 67.8008 83.1868 73.6122 86.7832Z"
        fill="url(#paint32_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M73.6122 86.7832C79.4235 90.3796 87.05 88.584 90.6463 82.7726C94.2426 76.9612 92.447 69.3347 86.6357 65.7384C80.8243 62.142 73.1979 63.9376 69.6015 69.749C66.0052 75.5604 67.8008 83.1868 73.6122 86.7832Z"
        fill="url(#paint33_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M78.666 76.6077C80.4905 84.357 82.6217 90.4854 83.4261 90.296C84.2305 90.1066 83.4035 83.6711 81.579 75.9219C79.7545 68.1726 77.6233 62.0442 76.8189 62.2335C76.0145 62.4229 76.8415 68.8585 78.666 76.6077Z"
        fill="url(#paint34_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M66.0912 79.5637C66.2806 80.3681 72.7161 79.5411 80.4652 77.7166C88.2144 75.8921 94.3429 73.7609 94.1535 72.9565C93.9641 72.1521 87.5286 72.9791 79.7794 74.8036C72.0302 76.6281 65.9018 78.7593 66.0912 79.5637Z"
        fill="url(#paint35_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M205.575 26.19C201.786 36.2587 200.25 44.9985 202.144 45.711C204.037 46.4234 208.643 38.8387 212.432 28.77C216.22 18.7013 217.756 9.96152 215.862 9.24908C213.969 8.53664 209.363 16.1214 205.575 26.19Z"
        fill="url(#paint36_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M184.95 18.4291C184.238 20.3218 194.43 25.908 207.714 30.906C220.998 35.9041 232.344 38.4214 233.056 36.5287C233.768 34.6359 223.577 29.0498 210.293 24.0518C197.008 19.0537 185.662 16.5363 184.95 18.4291Z"
        fill="url(#paint37_radial_2235_1765)"
      />
      <path
        d="M206.838 21.9173C204.125 23.1466 203 26.512 204.325 29.4342C205.649 32.3563 208.921 33.7286 211.634 32.4993C214.346 31.27 215.471 27.9046 214.147 24.9824C212.822 22.0603 209.55 20.688 206.838 21.9173Z"
        fill="#333333"
      />
      <path
        d="M207.182 22.6771C204.47 23.9064 203.268 27.1018 204.497 29.8141C205.726 32.5264 208.921 33.7286 211.634 32.4993C214.346 31.27 215.548 28.0747 214.319 25.3624C213.09 22.65 209.894 21.4478 207.182 22.6771Z"
        fill="white"
      />
      <path
        d="M207.605 23.6139C205.684 24.4847 204.883 26.8595 205.816 28.9182C206.749 30.9768 209.063 31.9398 210.984 31.0689C212.905 30.1981 213.707 27.8233 212.773 25.7647C211.84 23.706 209.526 22.7431 207.605 23.6139Z"
        fill="#333333"
      />
      <path
        d="M207.83 24.1111C205.909 24.982 205.057 27.2454 205.928 29.1668C206.799 31.0881 209.063 31.9398 210.984 31.0689C212.905 30.1981 213.757 27.9346 212.886 26.0133C212.015 24.0919 209.752 23.2403 207.83 24.1111Z"
        fill="#246FF2"
      />
      <path
        d="M207.49 28.4999C207.721 29.6826 208.866 30.4544 210.049 30.2238C211.232 29.9933 212.004 28.8476 211.773 27.6649C211.542 26.4822 210.397 25.7104 209.214 25.9409C208.031 26.1715 207.259 27.3172 207.49 28.4999Z"
        fill="white"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M197.665 23.2182C195.258 29.6146 198.492 36.7509 204.889 39.1574C211.285 41.564 218.421 38.3296 220.828 31.9332C223.235 25.5369 220 18.4006 213.604 15.9941C207.207 13.5875 200.071 16.8218 197.665 23.2182Z"
        fill="url(#paint38_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M197.665 23.2182C195.258 29.6146 198.492 36.7509 204.889 39.1574C211.285 41.564 218.421 38.3296 220.828 31.9332C223.235 25.5369 220 18.4006 213.604 15.9941C207.207 13.5875 200.071 16.8218 197.665 23.2182Z"
        fill="url(#paint39_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M208.629 26.2093C201.378 29.4957 195.777 32.7701 196.118 33.5227C196.459 34.2754 202.614 32.2214 209.865 28.935C217.116 25.6486 222.718 22.3742 222.376 21.6215C222.035 20.8688 215.881 22.9228 208.629 26.2093Z"
        fill="url(#paint40_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M203.297 14.4451C202.544 14.7863 204.598 20.941 207.884 28.1921C211.171 35.4432 214.445 41.0448 215.198 40.7036C215.95 40.3625 213.896 34.2078 210.61 26.9567C207.324 19.7056 204.049 14.104 203.297 14.4451Z"
        fill="url(#paint41_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M325.496 79.0989C326.906 89.7639 329.675 98.1947 331.68 97.9295C333.686 97.6644 334.169 88.8038 332.76 78.1388C331.35 67.4738 328.581 59.043 326.575 59.3082C324.57 59.5733 324.087 68.4339 325.496 79.0989Z"
        fill="url(#paint42_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M303.65 81.9905C303.915 83.9954 315.536 84.1129 329.607 82.2529C343.678 80.393 354.87 77.2599 354.605 75.2551C354.34 73.2502 342.718 73.1328 328.647 74.9927C314.576 76.8527 303.385 79.9857 303.65 81.9905Z"
        fill="url(#paint43_radial_2235_1765)"
      />
      <path
        d="M324.61 74.735C322.799 77.0985 323.394 80.5967 325.94 82.5484C328.487 84.5002 332.02 84.1664 333.831 81.8029C335.643 79.4394 335.047 75.9412 332.501 73.9895C329.955 72.0377 326.422 72.3715 324.61 74.735Z"
        fill="#333333"
      />
      <path
        d="M325.272 75.2425C323.461 77.606 323.908 80.9906 326.271 82.8022C328.635 84.6138 332.02 84.1664 333.831 81.8029C335.643 79.4394 335.195 76.0548 332.832 74.2432C330.468 72.4317 327.084 72.879 325.272 75.2425Z"
        fill="white"
      />
      <path
        d="M326.084 75.8724C324.801 77.5467 325.215 80.0186 327.009 81.3936C328.803 82.7686 331.297 82.526 332.581 80.8518C333.864 79.1775 333.45 76.7057 331.656 75.3306C329.862 73.9556 327.368 74.1982 326.084 75.8724Z"
        fill="#333333"
      />
      <path
        d="M326.52 76.1967C325.236 77.871 325.553 80.2685 327.228 81.5518C328.902 82.8351 331.299 82.5182 332.583 80.844C333.866 79.1697 333.549 76.7722 331.875 75.4889C330.201 74.2056 327.803 74.5225 326.52 76.1967Z"
        fill="#246FF2"
      />
      <path
        d="M328.291 80.237C329.052 81.1711 330.426 81.3112 331.36 80.5499C332.294 79.7887 332.435 78.4144 331.673 77.4803C330.912 76.5463 329.538 76.4062 328.604 77.1674C327.67 77.9287 327.53 79.303 328.291 80.237Z"
        fill="white"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M317.139 80.2061C318.035 86.9814 324.253 91.7478 331.028 90.8522C337.803 89.9566 342.57 83.7382 341.674 76.9629C340.779 70.1877 334.56 65.4213 327.785 66.3169C321.01 67.2125 316.243 73.4309 317.139 80.2061Z"
        fill="url(#paint44_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M317.139 80.2061C318.035 86.9814 324.253 91.7478 331.028 90.8522C337.803 89.9566 342.57 83.7382 341.674 76.9629C340.779 70.1877 334.56 65.4213 327.785 66.3169C321.01 67.2125 316.243 73.4309 317.139 80.2061Z"
        fill="url(#paint45_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M328.217 77.6748C323.374 83.9933 319.979 89.523 320.635 90.0258C321.291 90.5285 325.749 85.8139 330.592 79.4954C335.435 73.1769 338.83 67.6471 338.174 67.1444C337.518 66.6417 333.06 71.3563 328.217 77.6748Z"
        fill="url(#paint46_radial_2235_1765)"
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d="M317.964 69.8132C317.462 70.4691 322.176 74.9269 328.495 79.77C334.813 84.6131 340.343 88.0075 340.846 87.3516C341.348 86.6958 336.634 82.2379 330.315 77.3948C323.997 72.5517 318.467 69.1573 317.964 69.8132Z"
        fill="url(#paint47_radial_2235_1765)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(389.196 209.739) rotate(-135) scale(3.61037 18.9836)"
        >
          <stop stopColor="#BFEAFF" />
          <stop offset="0.43" stopColor="#6E8793" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(383.589 205.052) rotate(135) scale(3.60988 25.0363)"
        >
          <stop stopColor="#DB494A" />
          <stop offset="0.61" stopColor="#482F44" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(383.613 205.095) rotate(-90) scale(12.0428 12.0429)"
        >
          <stop stopColor="#29ABE2" />
          <stop offset="0.49" stopColor="#155976" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(383.613 205.095) rotate(-90) scale(12.0428 12.0429)"
        >
          <stop stopColor="#29ABE2" />
          <stop offset="0.49" stopColor="#155976" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(384.508 202.731) rotate(-90) scale(1.41701 14.0757)"
        >
          <stop stopColor="#BFEAFF" />
          <stop offset="0.43" stopColor="#6E8793" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(376.888 204.544) rotate(180) scale(1.41717 14.0771)"
        >
          <stop stopColor="#BFEAFF" />
          <stop offset="0.43" stopColor="#6E8793" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(334.395 338.267) rotate(-89.467) scale(3.61037 18.9835)"
        >
          <stop stopColor="#BFEAFF" />
          <stop offset="0.43" stopColor="#6E8793" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(333.812 330.979) rotate(-179.467) scale(3.60988 25.0363)"
        >
          <stop stopColor="#DB494A" />
          <stop offset="0.61" stopColor="#482F44" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(333.779 331.029) rotate(-44.4669) scale(12.0428 12.0429)"
        >
          <stop stopColor="#29ABE2" />
          <stop offset="0.49" stopColor="#155976" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(333.779 331.029) rotate(-44.4669) scale(12.0428 12.0429)"
        >
          <stop stopColor="#29ABE2" />
          <stop offset="0.49" stopColor="#155976" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint10_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(336.095 330.011) rotate(-44.4669) scale(1.41701 14.0757)"
        >
          <stop stopColor="#BFEAFF" />
          <stop offset="0.43" stopColor="#6E8793" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint11_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(329.46 325.839) rotate(-134.467) scale(1.41717 14.0771)"
        >
          <stop stopColor="#BFEAFF" />
          <stop offset="0.43" stopColor="#6E8793" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint12_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(211.215 385.471) rotate(-161.357) scale(3.61037 18.9835)"
        >
          <stop stopColor="#BFEAFF" />
          <stop offset="0.43" stopColor="#6E8793" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint13_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(204.109 383.761) rotate(108.643) scale(3.60988 25.0363)"
        >
          <stop stopColor="#DB494A" />
          <stop offset="0.61" stopColor="#482F44" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint14_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(204.15 383.786) rotate(-116.357) scale(12.0428 12.0429)"
        >
          <stop stopColor="#29ABE2" />
          <stop offset="0.49" stopColor="#155976" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint15_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(204.15 383.786) rotate(-116.357) scale(12.0428 12.0429)"
        >
          <stop stopColor="#29ABE2" />
          <stop offset="0.49" stopColor="#155976" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint16_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(203.899 381.267) rotate(-116.357) scale(1.41701 14.0757)"
        >
          <stop stopColor="#BFEAFF" />
          <stop offset="0.43" stopColor="#6E8793" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint17_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(197.877 386.284) rotate(153.643) scale(1.41717 14.0771)"
        >
          <stop stopColor="#BFEAFF" />
          <stop offset="0.43" stopColor="#6E8793" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint18_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(72.0007 331.958) rotate(9.61145) scale(3.61037 18.9835)"
        >
          <stop stopColor="#BFEAFF" />
          <stop offset="0.43" stopColor="#6E8793" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint19_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(79.287 332.531) rotate(-80.3884) scale(3.60988 25.0363)"
        >
          <stop stopColor="#DB494A" />
          <stop offset="0.61" stopColor="#482F44" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint20_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(79.2404 332.509) rotate(54.6115) scale(12.0428 12.0429)"
        >
          <stop stopColor="#29ABE2" />
          <stop offset="0.49" stopColor="#155976" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint21_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(79.2404 332.509) rotate(54.6115) scale(12.0428 12.0429)"
        >
          <stop stopColor="#29ABE2" />
          <stop offset="0.49" stopColor="#155976" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint22_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(79.8832 334.958) rotate(54.6115) scale(1.41701 14.0757)"
        >
          <stop stopColor="#BFEAFF" />
          <stop offset="0.43" stopColor="#6E8793" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint23_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(85.045 329.059) rotate(-35.3885) scale(1.41717 14.0771)"
        >
          <stop stopColor="#BFEAFF" />
          <stop offset="0.43" stopColor="#6E8793" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint24_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.8648 211.737) rotate(-58.2489) scale(3.61037 18.9835)"
        >
          <stop stopColor="#BFEAFF" />
          <stop offset="0.43" stopColor="#6E8793" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint25_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.1389 205.204) rotate(-148.249) scale(3.60988 25.0363)"
        >
          <stop stopColor="#DB494A" />
          <stop offset="0.61" stopColor="#482F44" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint26_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.1052 205.238) rotate(-13.2488) scale(12.0428 12.0429)"
        >
          <stop stopColor="#29ABE2" />
          <stop offset="0.49" stopColor="#155976" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint27_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.1052 205.238) rotate(-13.2488) scale(12.0428 12.0429)"
        >
          <stop stopColor="#29ABE2" />
          <stop offset="0.49" stopColor="#155976" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint28_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.6136 205.569) rotate(-13.2488) scale(1.41701 14.0757)"
        >
          <stop stopColor="#BFEAFF" />
          <stop offset="0.43" stopColor="#6E8793" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint29_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24.0993 198.564) rotate(-103.249) scale(1.41717 14.0771)"
        >
          <stop stopColor="#BFEAFF" />
          <stop offset="0.43" stopColor="#6E8793" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint30_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(76.8824 82.76) rotate(-58.2489) scale(3.61037 18.9835)"
        >
          <stop stopColor="#BFEAFF" />
          <stop offset="0.43" stopColor="#6E8793" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint31_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(80.1565 76.2278) rotate(-148.249) scale(3.60988 25.0363)"
        >
          <stop stopColor="#DB494A" />
          <stop offset="0.61" stopColor="#482F44" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint32_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(80.1228 76.261) rotate(-13.2488) scale(12.0428 12.0429)"
        >
          <stop stopColor="#29ABE2" />
          <stop offset="0.49" stopColor="#155976" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint33_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(80.1228 76.261) rotate(-13.2488) scale(12.0428 12.0429)"
        >
          <stop stopColor="#29ABE2" />
          <stop offset="0.49" stopColor="#155976" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint34_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(82.6312 76.5923) rotate(-13.2488) scale(1.41701 14.0757)"
        >
          <stop stopColor="#BFEAFF" />
          <stop offset="0.43" stopColor="#6E8793" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint35_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(79.1169 69.587) rotate(-103.249) scale(1.41717 14.0771)"
        >
          <stop stopColor="#BFEAFF" />
          <stop offset="0.43" stopColor="#6E8793" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint36_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(202.244 25.6456) rotate(20.6184) scale(3.61037 18.9835)"
        >
          <stop stopColor="#BFEAFF" />
          <stop offset="0.43" stopColor="#6E8793" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint37_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(209.287 27.5984) rotate(-69.3814) scale(3.60988 25.0363)"
        >
          <stop stopColor="#DB494A" />
          <stop offset="0.61" stopColor="#482F44" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint38_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(209.246 27.5747) rotate(65.6185) scale(12.0428 12.0429)"
        >
          <stop stopColor="#29ABE2" />
          <stop offset="0.49" stopColor="#155976" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint39_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(209.246 27.5747) rotate(65.6185) scale(12.0428 12.0429)"
        >
          <stop stopColor="#29ABE2" />
          <stop offset="0.49" stopColor="#155976" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint40_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(209.41 30.0968) rotate(65.6185) scale(1.41701 14.0757)"
        >
          <stop stopColor="#BFEAFF" />
          <stop offset="0.43" stopColor="#6E8793" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint41_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(215.6 25.2994) rotate(-24.3815) scale(1.41717 14.0771)"
        >
          <stop stopColor="#BFEAFF" />
          <stop offset="0.43" stopColor="#6E8793" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint42_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(322.303 80.1899) rotate(-7.52991) scale(3.61037 18.9835)"
        >
          <stop stopColor="#BFEAFF" />
          <stop offset="0.43" stopColor="#6E8793" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint43_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(329.434 78.5943) rotate(-97.5297) scale(3.60988 25.0363)"
        >
          <stop stopColor="#DB494A" />
          <stop offset="0.61" stopColor="#482F44" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint44_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(329.406 78.5838) rotate(37.4702) scale(12.0428 12.0429)"
        >
          <stop stopColor="#29ABE2" />
          <stop offset="0.49" stopColor="#155976" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint45_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(329.406 78.5838) rotate(37.4702) scale(12.0428 12.0429)"
        >
          <stop stopColor="#29ABE2" />
          <stop offset="0.49" stopColor="#155976" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint46_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(330.739 80.7343) rotate(37.4702) scale(1.41701 14.0757)"
        >
          <stop stopColor="#BFEAFF" />
          <stop offset="0.43" stopColor="#6E8793" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint47_radial_2235_1765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(333.934 73.5793) rotate(-52.5298) scale(1.41717 14.0771)"
        >
          <stop stopColor="#BFEAFF" />
          <stop offset="0.43" stopColor="#6E8793" />
          <stop offset="1" />
        </radialGradient>
      </defs>
    </>
  );
};
