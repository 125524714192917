import React, { useEffect, useRef, useState } from 'react';

import * as dateFns from 'date-fns';
import { twMerge } from 'tailwind-merge';

import { type UserProjectSchema } from '~shared/api/client';
import { isDefined } from '~shared/lib';
import { AnimatedCounter } from '~shared/ui';

import { getTimerValues } from '../lib';

interface AirDropCountdownProps {
  className?: string;
  project: UserProjectSchema | null;
}

export const AirDropCountdown: React.FC<AirDropCountdownProps> = ({ className, project }) => {
  const timerRef = useRef<NodeJS.Timer>();
  const [secondsRemaning, setSecondsRemaining] = useState(0);

  const timerValues = getTimerValues(secondsRemaning);

  useEffect(() => {
    clearInterval(timerRef.current);
    const handleTimerUpdate = (): void => {
      const seconds = dateFns.differenceInSeconds(
        isDefined(project) &&
          isDefined(project.air_drop_time) &&
          project.air_drop_time > Math.floor(new Date().getTime() / 1000)
          ? new Date(project.air_drop_time * 1000)
          : dateFns.addMinutes(new Date(), new Date().getTimezoneOffset()),
        dateFns.addMinutes(new Date(), new Date().getTimezoneOffset())
      );

      setSecondsRemaining(Math.trunc(seconds));
    };

    handleTimerUpdate();
    timerRef.current = setInterval(handleTimerUpdate, 1000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [timerRef, project]);

  return (
    <div
      className={twMerge(
        'flex w-full flex-col items-center justify-center space-y-4 border-y border-border bg-background p-4',
        className
      )}
    >
      <p className="text-center">
        Time left until the end of the
        <br />
        <span className="text-blue">9,000,000$ Airdrop</span>
      </p>
      <div className="flex items-start space-x-[2px]">
        <div className="flex flex-col items-center space-y-1">
          <div className="flex items-center space-x-1 text-md font-bold">
            <AnimatedCounter className="h-[38px] rounded-xl bg-border px-[10px] py-2" value={timerValues.days[0]} />
            <AnimatedCounter className="h-[38px] rounded-xl bg-border px-[10px] py-2" value={timerValues.days[1]} />
          </div>
          <span className="text-white/40">d</span>
        </div>
        <span className="flex h-[38px] w-3 items-center justify-center text-md font-bold text-blue">:</span>
        <div className="flex flex-col items-center space-y-1">
          <div className="flex items-center space-x-1 text-md font-bold">
            <AnimatedCounter className="h-[38px] rounded-xl bg-border px-[10px] py-2" value={timerValues.hours[0]} />
            <AnimatedCounter className="h-[38px] rounded-xl bg-border px-[10px] py-2" value={timerValues.hours[1]} />
          </div>
          <span className="text-white/40">h</span>
        </div>
        <span className="flex h-[38px] w-3 items-center justify-center text-md font-bold text-blue">:</span>
        <div className="flex flex-col items-center space-y-1">
          <div className="flex items-center space-x-1 text-md font-bold">
            <AnimatedCounter className="h-[38px] rounded-xl bg-border px-[10px] py-2" value={timerValues.minutes[0]} />
            <AnimatedCounter className="h-[38px] rounded-xl bg-border px-[10px] py-2" value={timerValues.minutes[1]} />
          </div>
          <span className="text-white/40">min</span>
        </div>
        <span className="flex h-[38px] w-3 items-center justify-center text-md font-bold text-blue">:</span>
        <div className="flex flex-col items-center space-y-1">
          <div className="flex items-center space-x-1 text-md font-bold">
            <AnimatedCounter className="h-[38px] rounded-xl bg-border px-[10px] py-2" value={timerValues.seconds[0]} />
            <AnimatedCounter className="h-[38px] rounded-xl bg-border px-[10px] py-2" value={timerValues.seconds[1]} />
          </div>
          <span className="text-white/40">sec</span>
        </div>
      </div>
    </div>
  );
};
