export enum BoostPageTab {
  Top = 'top',
  Farming = 'farming',
  AutoFarming = 'autoFarming',
  DailyReward = 'dailyReward',
}

export const BOOST_PAGE_TABS_LIST = [
  {
    value: BoostPageTab.Top,
    label: 'Top',
  },
  {
    value: BoostPageTab.Farming,
    label: 'Farming',
  },
  {
    value: BoostPageTab.AutoFarming,
    label: 'Auto-Farming',
  },
  {
    value: BoostPageTab.DailyReward,
    label: 'Daily Reward',
  },
] as const;
