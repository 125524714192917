import React, { useCallback, useRef, useState } from 'react';

import { useStore } from 'app/store';
import html2canvas from 'html2canvas-pro';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';

import { useUserRefLink } from '~entities/user/lib';
import { ClipboardCopy } from '~features/clipboard-copy';
import { RefQr } from '~features/ref-qr';
import { useToast } from '~features/toaster/lib';
import { CopyIcon, QrIcon, ShareIcon } from '~shared/assets/icons';
import { isDefined } from '~shared/lib';
import { Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader, DrawerTitle, Hedgehog } from '~shared/ui';

export const ShareDrawer: React.FC = observer(() => {
  const {
    userStore: { isShareDrawerOpen, closeShareDrawer },
  } = useStore();
  const userRefInfo = useUserRefLink();
  const { toast, dismiss } = useToast();
  const qrRef = useRef<HTMLDivElement | null>(null);

  const [isQrCopyLoading, setIsQrCopyLoading] = useState(false);
  const [isShareLoading, setIsShareLoading] = useState(false);

  const handleClose = useCallback(
    (newOpen: boolean) => {
      if (!newOpen) {
        closeShareDrawer();
      }
    },
    [closeShareDrawer]
  );

  const jsxToDataUrl = useCallback(async () => {
    if (isNil(qrRef.current)) return '';

    const canvas = await html2canvas(qrRef.current, { scale: 4 });
    return canvas.toDataURL();
  }, [qrRef]);

  const handleCopyQr = useCallback(async () => {
    if (isNil(navigator.clipboard) || isNil(qrRef.current)) return;
    Telegram.WebApp.HapticFeedback.notificationOccurred('success');
    try {
      setIsQrCopyLoading(true);

      await navigator.clipboard.write([
        new ClipboardItem({
          'image/png': jsxToDataUrl(),
        }),
      ]);
      const toastedMessage = toast({ title: 'Qr copied' });
      setTimeout(() => {
        dismiss(toastedMessage.id);
      }, 2000);
      handleClose(false);
    } catch (error) {
      console.error(error);
      const toastedMessage = toast({ title: 'Error copying ' });

      setTimeout(() => {
        dismiss(toastedMessage.id);
      }, 2000);
    } finally {
      setIsQrCopyLoading(false);
    }
  }, [toast, dismiss, handleClose, jsxToDataUrl]);

  const handleShare = useCallback(async () => {
    if (isNil(userRefInfo)) return;
    Telegram.WebApp.HapticFeedback.notificationOccurred('success');
    try {
      setIsShareLoading(true);
      await navigator.share({ url: userRefInfo.refLink });
      handleClose(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsShareLoading(false);
    }
  }, [userRefInfo, handleClose]);

  return (
    <Drawer open={isShareDrawerOpen} onOpenChange={handleClose}>
      <DrawerContent>
        <DrawerHeader className="flex flex-col items-center">
          <DrawerTitle>Share link</DrawerTitle>
        </DrawerHeader>

        <div className="h-0 overflow-hidden">
          <div className="flex flex-col items-center bg-background p-4" ref={qrRef}>
            <Hedgehog className="mt-auto" color="green" showGlowing={false} withText />

            <RefQr
              className="mt-8"
              qrHexColor="#37B98C"
              sphereColorClassName="bg-green-glowing-sphere"
              usernameColorClassName="text-green"
            />
          </div>
        </div>

        <DrawerFooter className="flex flex-col space-y-3">
          <ClipboardCopy
            variant="wrapper"
            className="w-full items-center justify-center rounded-[10px] border border-border p-4 text-lg font-normal"
            size="wrapper"
            value={userRefInfo?.refLink ?? ''}
            label="Link copied"
            onClick={() => {
              Telegram.WebApp.HapticFeedback.notificationOccurred('success');
              handleClose(false);
            }}
          >
            <CopyIcon className="mr-2 size-6" />
            Copy link
          </ClipboardCopy>

          <Button
            variant="wrapper"
            className="w-full items-center justify-center rounded-[10px] border border-border p-4 text-lg font-normal"
            size="wrapper"
            onClick={handleCopyQr}
            isLoading={isQrCopyLoading}
          >
            {!isQrCopyLoading && <QrIcon className="mr-2 size-6" />}
            Share QR code
          </Button>

          {/* eslint-disable-next-line @typescript-eslint/unbound-method */}
          {isDefined(navigator.share) && (
            <Button
              variant="wrapper"
              className="w-full items-center justify-center rounded-[10px] border border-border p-4 text-lg font-normal"
              size="wrapper"
              onClick={handleShare}
              isLoading={isShareLoading}
            >
              {!isShareLoading && <ShareIcon className="mr-2 size-6" />}
              Send to Telegram
            </Button>
          )}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
});
