import React, { useCallback } from 'react';

import { useTonConnectModal } from '@tonconnect/ui-react';
import { useStore } from 'app/store';
import { observer } from 'mobx-react';

import { TelegramIcon } from '~shared/assets/icons/socials';
import { Button, Drawer, DrawerContent, DrawerDescription, DrawerFooter, DrawerHeader, DrawerTitle } from '~shared/ui';

export const ConnectWalletDrawer: React.FC = observer(() => {
  const { open } = useTonConnectModal();

  const {
    userStore: { isConnectWalletDrawerOpen, closeConnectWalletDrawer },
  } = useStore();

  const handleOpenChange = useCallback(
    (newOpen: boolean) => {
      if (!newOpen) {
        closeConnectWalletDrawer();
      }
    },
    [closeConnectWalletDrawer]
  );

  const handleOpenTonConnectModal = useCallback(() => {
    open();
    closeConnectWalletDrawer();
  }, [open, closeConnectWalletDrawer]);

  return (
    <Drawer open={isConnectWalletDrawerOpen} onOpenChange={handleOpenChange} shouldScaleBackground>
      <DrawerContent className="flex flex-col pb-20">
        <DrawerHeader className="flex flex-col items-center space-y-4">
          <DrawerTitle>Connect Wallet</DrawerTitle>
          <DrawerDescription>Connect a Wallet in Telegram or select a wallet to link.</DrawerDescription>
        </DrawerHeader>

        <DrawerFooter className="mt-8">
          <Button buttonColor="blue" onClick={handleOpenTonConnectModal}>
            <TelegramIcon className="mr-4 size-6 shrink-0" />
            Open Telegram Wallet
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
});
