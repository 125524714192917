import React from 'react';

import { useStore } from 'app/store';
import { observer } from 'mobx-react';
import { twMerge } from 'tailwind-merge';

import { type PaymentProductSchema } from '~shared/api/client';
import { TelegramStarIcon } from '~shared/assets/icons';
import { topBoostHedgehogImage } from '~shared/assets/images/hedgehogs';
import { formatAmount } from '~shared/lib';
import { Amount, Button } from '~shared/ui';

import { usePurchaseBoost } from '../lib';

interface TopBoostCardProps {
  className?: string;
  boost: PaymentProductSchema;
}

export const TopBoostCard: React.FC<TopBoostCardProps> = observer(({ boost, className }) => {
  const {
    projectStore: { project },
  } = useStore();

  const { handlePurchaseBoost, isBoostLoading, isBoostsDisabled } = usePurchaseBoost(boost);

  return (
    <div className={twMerge('flex flex-col rounded-lg bg-white/10 backdrop-blur-lg', className)}>
      <div className="flex flex-col items-center px-4 pb-1 pt-3">
        <img className="h-auto w-full max-w-[150px]" src={topBoostHedgehogImage} alt="" />
      </div>
      <hr className="w-full border-t-white/10" />
      <div className="flex flex-col items-center p-3">
        <span className="text-white/80">You can get</span>
        <Amount
          className="justify-start [&>*]:text-base [&>img]:mr-1 [&>img]:size-5"
          value={`+${formatAmount(boost.amount ?? 0)}`}
          logo={project?.logo_url}
        />
        <span className="text-white/80">rating</span>

        <Button
          className="mt-5 w-full p-2 text-base font-bold"
          buttonColor="blue"
          onClick={handlePurchaseBoost}
          isLoading={isBoostLoading}
          disabled={isBoostsDisabled}
        >
          <TelegramStarIcon className="mr-2 size-[22px]" />
          <span>{boost.price}</span>
        </Button>
      </div>
    </div>
  );
});
