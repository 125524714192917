import { makeAutoObservable } from 'mobx';

import { type GetDailyRewardSchema, WidgetsDailyRewardsApiService } from '~shared/api/client';

export class DailyBonusesStore {
  dailyBonuses: GetDailyRewardSchema | null;

  isDailyBonusDrawerOpen: boolean;

  isDailyBonusDrawerShown: boolean;

  constructor() {
    makeAutoObservable(this, {}, { deep: true, autoBind: true, name: 'jobStore' });

    this.dailyBonuses = null;
    this.isDailyBonusDrawerOpen = false;
    this.isDailyBonusDrawerShown = false;
  }

  async getDailyBonuses(userId: string): Promise<GetDailyRewardSchema | undefined> {
    try {
      const dailyBonusesResponse =
        await WidgetsDailyRewardsApiService.getDailyRewardsApiV2WidgetUsersUserIdDailyRewardsGet(userId);
      this.dailyBonuses = dailyBonusesResponse;
      return dailyBonusesResponse;
    } catch (error) {
      console.error(error);
    }
  }

  async claimDailyBonus(userId: string): Promise<GetDailyRewardSchema | undefined> {
    try {
      const dailyBonusesResponse =
        await WidgetsDailyRewardsApiService.claimApiV2WidgetUsersUserIdDailyRewardsClaimPost(userId);
      return dailyBonusesResponse;
    } catch (error) {
      console.error(error);
    }
  }

  openDailyBonusDrawer(): void {
    this.isDailyBonusDrawerOpen = true;
  }

  closeDailyBonusDrawer(): void {
    this.isDailyBonusDrawerOpen = false;
    this.isDailyBonusDrawerShown = true;
  }
}
