/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* eslint-disable */
import type { SubscribedToChannelResponse } from '../models/SubscribedToChannelResponse';
import type { UserSchema } from '../models/UserSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WebappAuthService {
  /**
   * Login Webapp
   * Get information about the user by `telegram id`
   * @param inviterTelegramId Telegram id of the user who invited the current user
   * @param geoIp IP of the user
   * @returns UserSchema Successful Response
   * @throws ApiError
   */
  public static loginWebappApiV2AuthWebappLoginGet(
    inviterTelegramId?: number | null,
    geoIp?: string | null
  ): CancelablePromise<UserSchema> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v2/auth/webapp/login',
      query: {
        inviter_telegram_id: inviterTelegramId,
        geo_ip: geoIp,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Subscribed To Channel
   * @returns SubscribedToChannelResponse Successful Response
   * @throws ApiError
   */
  public static subscribedToChannelApiV2AuthSubscribedToChannelGet(): CancelablePromise<SubscribedToChannelResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v2/auth/subscribed_to_channel',
    });
  }
}
