import type React from 'react';
import { useCallback, useMemo } from 'react';

import { useStore } from 'app/store';
import { observer } from 'mobx-react';

import { PaymentTaskSchema, type UserTaskSchema } from '~shared/api/client';
import { CheckIcon } from '~shared/assets/icons';
import { formatAmount, isDefined } from '~shared/lib';
import { Amount, Button } from '~shared/ui';

interface TaskListItemProps {
  task: UserTaskSchema | PaymentTaskSchema;
  onSelect: (event: React.MouseEvent, task: UserTaskSchema | PaymentTaskSchema) => void;
}

export const TaskListItem: React.FC<TaskListItemProps> = observer(({ task, onSelect }) => {
  const {
    projectStore: { project },
  } = useStore();

  const isCompleted = useMemo(() => {
    return (
      (task as UserTaskSchema)?.is_completed ?? (task as PaymentTaskSchema)?.state === PaymentTaskSchema.state.COMPLETED
    );
  }, [task]);

  const handleTaskSelect = useCallback(
    (event: React.MouseEvent) => {
      Telegram.WebApp.HapticFeedback.notificationOccurred('success');
      onSelect(event, task);
    },
    [onSelect, task]
  );

  return (
    <div
      key={task.id}
      className="flex w-full items-center rounded-[10px] bg-white/10 p-3 backdrop-blur-lg disabled:opacity-100"
    >
      {isDefined(task.type.logo_url) && (
        <img
          className="mr-3 size-[46px] overflow-hidden rounded-full object-cover object-center"
          src={task.type.logo_url}
          alt=""
        />
      )}
      <div className="mr-3 flex flex-col">
        <p className="text-base font-normal">{task.name}</p>
        <Amount
          className="mt-1 justify-start [&>*]:text-base [&>img]:mr-1 [&>img]:size-5"
          value={`+${formatAmount(parseFloat(task.amount))}`}
          logo={project?.logo_url}
        />
      </div>

      {isCompleted ? (
        <Button
          variant="wrapper"
          size="wrapper"
          className="ml-auto flex items-center rounded-xl border border-[#8FF3D01A] px-5 py-2 text-mint"
          onClick={handleTaskSelect}
          asChild
        >
          <a href={(task as UserTaskSchema).link} target="_blank" rel="noreferrer">
            <CheckIcon className="mr-2 size-[18px]" />
            <span>Done</span>
          </a>
        </Button>
      ) : (
        <Button
          className="ml-auto rounded-xl px-5 py-2 text-base font-normal"
          buttonColor="blue"
          onClick={handleTaskSelect}
          asChild
        >
          <a href={(task as UserTaskSchema).link} target="_blank" rel="noreferrer">
            {(task as PaymentTaskSchema).state === PaymentTaskSchema.state.READY ? 'Claim' : 'Start'}
          </a>
        </Button>
      )}
    </div>
  );
});
