import { useCallback, useEffect, useState } from 'react';

import { useStore } from 'app/store';

import {
  type GetMyTopPosition,
  type GetTopResponse,
  type GetTopUserResponse,
  WidgetsProjectApiService,
} from '~shared/api/client';
import { isDefined } from '~shared/lib';

export const useTopUsers = (): {
  topUsers: {
    place1: GetTopUserResponse | null;
    place2: GetTopUserResponse | null;
    place3: GetTopUserResponse | null;
    rest: GetTopUserResponse[] | null;
  };
  userTopPosition: GetMyTopPosition | null;
} => {
  const {
    userStore: { user },
    projectStore: { project },
  } = useStore();

  const [userTopPosition, setUserTopPosition] = useState<GetMyTopPosition | null>(null);
  const [topUsers, setTopUsers] = useState<GetTopResponse | null>(null);

  const getTopUsers = useCallback(async (userId: string, projectId: string) => {
    try {
      const topResponse = await WidgetsProjectApiService.getTopApiV2WidgetUsersUserIdProjectsProjectIdTopGet(
        userId,
        projectId,
        100,
        0
      );
      setTopUsers(topResponse);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getUserTopPosition = useCallback(async (userId: string, projectId: string) => {
    try {
      const userTopPositionResponse =
        await WidgetsProjectApiService.getMyTopPositionApiV2WidgetUsersUserIdProjectsProjectIdMyTopPositionGet(
          userId,
          projectId
        );
      setUserTopPosition(userTopPositionResponse);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (isDefined(user) && isDefined(user.id) && isDefined(project) && isDefined(project.id)) {
      void getTopUsers(user.id, project.id);
      void getUserTopPosition(user.id, project.id);
    }
  }, [getTopUsers, getUserTopPosition, user, project]);

  return {
    topUsers: {
      place1: topUsers?.results[0] ?? null,
      place2: topUsers?.results[1] ?? null,
      place3: topUsers?.results[2] ?? null,
      rest: isDefined(topUsers) ? topUsers.results.slice(3, topUsers.results.length) : null,
    },
    userTopPosition,
  };
};
