import React, { useCallback, useState } from 'react';

import { useStore } from 'app/store';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import { useToast } from '~features/toaster/lib';
import { hedgehogsImage } from '~shared/assets/images/hedgehogs';
import { isDefined } from '~shared/lib';
import { AppRoutes } from '~shared/model';
import {
  Amount,
  Button,
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  Skeleton,
} from '~shared/ui';

export const DailyBonusDrawer: React.FC = observer(() => {
  const {
    projectStore: { project, getProject },
    userStore: { user },
    dailyBonusesStore: {
      isDailyBonusDrawerOpen,
      closeDailyBonusDrawer,
      getDailyBonuses,
      claimDailyBonus,
      dailyBonuses,
    },
  } = useStore();
  const { toast } = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenChange = useCallback(
    (newOpen: boolean) => {
      if (!newOpen) {
        closeDailyBonusDrawer();
      }
    },
    [closeDailyBonusDrawer]
  );

  const handleClaim = useCallback(async () => {
    Telegram.WebApp.HapticFeedback.notificationOccurred('success');
    if (isNil(user) || isNil(user.id) || isNil(project) || isNil(project.id)) return;

    setIsLoading(true);
    const response = await claimDailyBonus(user.id);
    if (isDefined(response)) {
      navigate(AppRoutes.DailyBonuses);
      await getDailyBonuses(user.id);
      await getProject(project.id, user.id);
      handleOpenChange(false);
    } else {
      toast({
        title: 'Error',
        description: 'Unknown error',
        variant: 'destructive',
      });
    }
    setIsLoading(false);
  }, [navigate, handleOpenChange, claimDailyBonus, getDailyBonuses, project, getProject, toast, user]);

  return (
    <Drawer open={isDailyBonusDrawerOpen} onOpenChange={handleOpenChange} shouldScaleBackground>
      <DrawerContent className="flex flex-col pb-0">
        <DrawerHeader className="flex flex-col items-center">
          <img className="mb-3 w-[200px]" src={hedgehogsImage} alt="" />

          <DrawerTitle>
            Take your <span className="text-blue">daily bonus</span>
          </DrawerTitle>
          <DrawerDescription>Come back every day to increase your earnings </DrawerDescription>
        </DrawerHeader>

        {isDefined(dailyBonuses) && (
          <div className="mx-auto mt-8 grid grid-cols-3 gap-3">
            {isDefined(dailyBonuses.days[dailyBonuses.day_passed - 1]) ? (
              <div className="my-auto ml-auto flex flex-col items-center rounded-[14px] border border-border bg-black/10 px-3 py-6 opacity-20">
                <span className="text-[8px]">{dailyBonuses.day_passed} day</span>
                <Amount
                  className="mt-2 [&>*]:text-xs [&>img]:mr-1 [&>img]:size-4"
                  value={`+${dailyBonuses.days[dailyBonuses.day_passed - 1].reward}`}
                />
              </div>
            ) : (
              <div />
            )}
            <div className="flex flex-col items-center rounded-[20px] border border-blue bg-black/10 px-4 py-8">
              <span className="text-xs">{dailyBonuses.day_passed + 1} day</span>
              <Amount
                className="mt-2 [&>*]:text-base [&>img]:mr-1 [&>img]:size-5"
                value={`+${dailyBonuses.days[dailyBonuses.day_passed].reward}`}
              />
            </div>
            {isDefined(dailyBonuses) && isDefined(dailyBonuses.days[dailyBonuses.day_passed + 1]) ? (
              <div className="my-auto mr-auto flex flex-col items-center rounded-[14px] border border-border bg-black/10 px-3 py-6 opacity-20">
                <span className="text-[8px]">{dailyBonuses.day_passed + 2} day</span>
                <Amount
                  className="mt-2 [&>*]:text-xs [&>img]:mr-1 [&>img]:size-4"
                  value={`+${dailyBonuses.days[dailyBonuses.day_passed + 1].reward}`}
                />
              </div>
            ) : (
              <div />
            )}
          </div>
        )}

        {isDefined(dailyBonuses) && isDefined(user) ? (
          <Button className="mb-10 mt-8" buttonColor="blue" onClick={handleClaim} isLoading={isLoading}>
            Take Bonus
            <Amount
              className="ml-2 [&>*]:text-lg [&>*]:font-bold [&>img]:mr-1 [&>img]:size-5"
              value={`+${dailyBonuses.days[dailyBonuses.day_passed].reward}`}
            />
          </Button>
        ) : (
          <Skeleton className="mb-10 mt-8 h-[60px] w-full" />
        )}
      </DrawerContent>
    </Drawer>
  );
});
