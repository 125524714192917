import { FortunePrizeType } from '~shared/api/client';
import coinImage from '~shared/assets/images/_coin.png';
import { greenHedgehogImage } from '~shared/assets/images/hedgehogs';

export const FORTUNUE_PRIZE_TYPES_MAP = {
  [FortunePrizeType.FORTUNE_LIVES]: {
    color: '#37B98C',
    icon: greenHedgehogImage,
  },
  [FortunePrizeType.GRAPH_DEX_COINS]: {
    color: '#343442',
    icon: coinImage,
  },
  [FortunePrizeType.NOT_COINS]: {
    color: '#DB494A',
    currency: 'NOT',
  },
  [FortunePrizeType.TON_COINS]: {
    color: '#246EF0',
    currency: 'TON',
  },
} as const;

export const getPrizeInfo = (prizeType: FortunePrizeType): { color: string; currency?: string; icon?: string } => {
  return FORTUNUE_PRIZE_TYPES_MAP[prizeType];
};
