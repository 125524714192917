/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* eslint-disable */
export type PaymentToInfoSchema = {
  address: string;
  amount: string;
  currency: PaymentToInfoSchema.currency;
};
export namespace PaymentToInfoSchema {
  export enum currency {
    TON = 'ton',
  }
}
