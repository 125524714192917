import { makeAutoObservable } from 'mobx';

import {
  type CreatePaymentSchema,
  type GetPaymentProductsResponse,
  type PaymentProductSchema,
  WebappPaymentService,
} from '~shared/api/client';

export class BoostStore {
  boosts: GetPaymentProductsResponse | null;

  autoFarmingBoostActivatedDrawerProps: { boost: PaymentProductSchema } | null;

  isAutoFarmingWelcomeDrawerOpen: boolean;
  isAutoFarmingWelcomeDrawerShown: boolean;

  loaded: {
    boosts: boolean;
  };

  loading: {
    purchase: boolean;
  };

  constructor() {
    makeAutoObservable(this, {}, { deep: true, autoBind: true, name: 'boostStore' });

    this.boosts = null;
    this.autoFarmingBoostActivatedDrawerProps = null;
    this.isAutoFarmingWelcomeDrawerOpen = false;
    this.isAutoFarmingWelcomeDrawerShown = false;

    this.loaded = {
      boosts: false,
    };

    this.loading = {
      purchase: false,
    };
  }

  async getBoosts(userId: string, projectId: string): Promise<GetPaymentProductsResponse | undefined> {
    try {
      const boostsResponse =
        await WebappPaymentService.getPaymentProductsApiV2WidgetUsersUserIdProjectsProjectIdPaymentsProductGet(
          userId,
          projectId
        );
      this.boosts = boostsResponse;
      return boostsResponse;
    } catch (error) {
      console.error(error);
    } finally {
      this.loaded.boosts = true;
    }
  }

  async purchaseBoost(
    userId: string,
    projectId: string,
    payload: CreatePaymentSchema,
    callback: () => Promise<unknown>
  ): Promise<void> {
    try {
      this.loading.purchase = true;
      const purchaseBoostResponse =
        await WebappPaymentService.createPaymentsApiV2WidgetUsersUserIdProjectsProjectIdPaymentsPost(
          projectId,
          userId,
          payload
        );
      Telegram.WebApp.openInvoice(purchaseBoostResponse.payment_link_url, (status) => {
        if (status === 'paid') {
          void this.getBoosts(userId, projectId);
          void callback();
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.loading.purchase = false;
    }
  }

  openAutoFarmingBoostActivatedDrawer(boost: PaymentProductSchema): void {
    this.autoFarmingBoostActivatedDrawerProps = { boost };
  }

  closeAutoFarmingBoostActivatedDrawer(): void {
    this.autoFarmingBoostActivatedDrawerProps = null;
  }

  openAutoFarmingWelcomeDrawer(): void {
    this.isAutoFarmingWelcomeDrawerOpen = true;
  }

  closeAutoFarmingWelcomeDrawer(): void {
    this.isAutoFarmingWelcomeDrawerOpen = false;
    this.isAutoFarmingWelcomeDrawerShown = true;
  }
}
