import type React from 'react';
import { useCallback, useEffect, useState } from 'react';

import { useStore } from 'app/store';
import * as dateFns from 'date-fns';
import { observer } from 'mobx-react';

import { AUTO_FARMING_LIFE_TIME_MAP } from '~entities/boost';
import { autofarmingBoostHedgehogImage } from '~shared/assets/images/hedgehogs';
import { formatAmount, isDefined } from '~shared/lib';
import {
  Amount,
  Button,
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '~shared/ui';

export const AutoFarmingWelcomeDrawer: React.FC = observer(() => {
  const {
    projectStore: { project },
    jobStore: { latestJob },
    boostsStore: { isAutoFarmingWelcomeDrawerOpen, closeAutoFarmingWelcomeDrawer },
  } = useStore();

  const [amount, setAmount] = useState<string>('');

  const handleOpenChange = useCallback(
    (newOpen: boolean) => {
      if (!newOpen) {
        closeAutoFarmingWelcomeDrawer();
      }
    },
    [closeAutoFarmingWelcomeDrawer]
  );

  useEffect(() => {
    const secondsRemaning = dateFns.differenceInSeconds(
      isDefined(latestJob) ? new Date(latestJob.end_period) : new Date(),
      dateFns.addMinutes(new Date(), new Date().getTimezoneOffset())
    );

    const autoFarmingLifetime =
      isDefined(latestJob) && isDefined(latestJob.auto_farming_purchased_id)
        ? AUTO_FARMING_LIFE_TIME_MAP[latestJob.auto_farming_purchased_id as keyof typeof AUTO_FARMING_LIFE_TIME_MAP]
        : null;

    setAmount(
      formatAmount(
        Math.round(latestJob?.amount ?? 0) * (1 - secondsRemaning / (autoFarmingLifetime ?? project?.lifetime ?? 1))
      )
    );
  }, [latestJob, project]);

  return (
    <Drawer open={isAutoFarmingWelcomeDrawerOpen} onOpenChange={handleOpenChange}>
      <DrawerContent hideCloseButton>
        <DrawerHeader className="mb-0 flex flex-col items-center">
          <img className="h-auto w-[187px]" src={autofarmingBoostHedgehogImage} alt="" />
          <DrawerTitle className="mt-8 leading-snug">Welcome back!</DrawerTitle>
          <DrawerDescription className="mt-4 text-base text-white">
            While you were away, auto-farming earned you
          </DrawerDescription>
        </DrawerHeader>

        <Amount className="mt-8 [&>*]:text-2xl [&>img]:mr-1 [&>img]:size-10" value={`+${amount}`} />

        <DrawerFooter className="mt-8 gap-4">
          <Button className="h-[60px]" buttonColor="blue" onClick={closeAutoFarmingWelcomeDrawer}>
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
});
