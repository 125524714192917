/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* eslint-disable */
import type { CreatePaymentResponse } from '../models/CreatePaymentResponse';
import type { CreatePaymentSchema } from '../models/CreatePaymentSchema';
import type { GetPaymentProductsResponse } from '../models/GetPaymentProductsResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WebappPaymentService {
  /**
   * Get Payment Products
   * @param userId
   * @param projectId
   * @returns GetPaymentProductsResponse Successful Response
   * @throws ApiError
   */
  public static getPaymentProductsApiV2WidgetUsersUserIdProjectsProjectIdPaymentsProductGet(
    userId: string,
    projectId: string
  ): CancelablePromise<GetPaymentProductsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v2/widget/users/{user_id}/projects/{project_id}/payments/product',
      path: {
        user_id: userId,
        project_id: projectId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Create Payments
   * @param projectId
   * @param userId
   * @param requestBody
   * @returns CreatePaymentResponse Successful Response
   * @throws ApiError
   */
  public static createPaymentsApiV2WidgetUsersUserIdProjectsProjectIdPaymentsPost(
    projectId: string,
    userId: string,
    requestBody: CreatePaymentSchema
  ): CancelablePromise<CreatePaymentResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v2/widget/users/{user_id}/projects/{project_id}/payments',
      path: {
        project_id: projectId,
        user_id: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
