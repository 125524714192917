import React, { useCallback } from 'react';

import { useStore } from 'app/store';
import { observer } from 'mobx-react';
import { twMerge } from 'tailwind-merge';

import { type PaymentProductSchema } from '~shared/api/client';
import { TelegramStarIcon } from '~shared/assets/icons';
import { autofarmingBoostHedgehogImage } from '~shared/assets/images/hedgehogs';
import { isDefined } from '~shared/lib';
import { AnimatedCounter, Button } from '~shared/ui';

import { usePurchaseBoost, useActiveBoostTimer } from '../lib';

interface AutoFarmingBoostCardProps {
  className?: string;
  boost: PaymentProductSchema;
}

export const AutoFarmingBoostCard: React.FC<AutoFarmingBoostCardProps> = observer(({ className, boost }) => {
  const {
    boostsStore: { openAutoFarmingBoostActivatedDrawer },
    jobStore: { latestJob },
  } = useStore();

  const purchaseAutoFarmingCallback = useCallback(() => {
    openAutoFarmingBoostActivatedDrawer(boost);
  }, [openAutoFarmingBoostActivatedDrawer, boost]);

  const { handlePurchaseBoost, isBoostLoading, isBoostsDisabled } = usePurchaseBoost(
    boost,
    purchaseAutoFarmingCallback
  );
  const { isActive, timerValues } = useActiveBoostTimer(
    boost,
    isDefined(latestJob) && isDefined(latestJob.auto_farming_purchased_id) ? latestJob.end_period : null,
    latestJob?.auto_farming_purchased_id
  );

  return (
    <div className={twMerge('flex flex-col rounded-lg bg-white/10 backdrop-blur-lg', className)}>
      <div className="relative flex flex-col items-center p-2 pb-[30px] pt-[10px]">
        <img className="h-auto w-full max-w-[187px]" src={autofarmingBoostHedgehogImage} alt="" />
        <div className="absolute bottom-[-15px] left-1/2 -translate-x-1/2 rounded-xl border border-white/10 bg-[#343442] px-3 py-1 text-sm font-semibold">
          {boost.days} days
        </div>
      </div>

      <hr className="w-full border-t-white/10" />

      <div className="flex flex-col items-center p-5 pb-4 pt-7">
        <p className="text-center font-bold">{boost.title}</p>
        <p className="mt-1 text-center">{boost.description}</p>

        {isActive ? (
          <Button className="mt-5 w-full max-w-[200px] p-2 text-base font-normal" buttonColor="green">
            <span className="flex h-[18px] items-center text-white/80">
              left <AnimatedCounter className="ml-[1ch] font-bold text-white" value={timerValues.days} />d{' '}
              <AnimatedCounter className="ml-[1ch] font-bold text-white" value={timerValues.hours} />
              h <AnimatedCounter className="ml-[1ch] font-bold text-white" value={timerValues.minutes} />
              min
            </span>
          </Button>
        ) : (
          <Button
            className="mt-5 w-full max-w-[164px] p-2 text-base font-bold"
            buttonColor="blue"
            onClick={handlePurchaseBoost}
            isLoading={isBoostLoading}
            disabled={isBoostsDisabled || isDefined(latestJob?.auto_farming_purchased_id)}
          >
            <TelegramStarIcon className="mr-2 size-[22px]" />
            <span>{boost.price}</span>
          </Button>
        )}
      </div>
    </div>
  );
});
