/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* eslint-disable */
import type { GetAirdropsListResponse } from '../models/GetAirdropsListResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WidgetsAirDropsApiService {
  /**
   * Get Airdrops List
   * @param limit
   * @param offset
   * @returns GetAirdropsListResponse Successful Response
   * @throws ApiError
   */
  public static getAirdropsListApiV2WidgetAirdropsGet(
    limit: number = 20,
    offset?: number
  ): CancelablePromise<GetAirdropsListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v2/widget/airdrops',
      query: {
        limit: limit,
        offset: offset,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
