import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useStore } from 'app/store';
import cn from 'classnames';
import { motion, useMotionValue } from 'framer-motion';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';

import { InviteeTaskListItem, TaskListItem } from '~entities/task';
import { STORIES_TASKS_TYPE_ID } from '~entities/task/model/payment-tasks-id.model';
import { PaymentTaskSchema, UserInviteeTaskSchema, type UserTaskSchema } from '~shared/api/client';
import { formatAmount, isDefined, useScreenDimensions } from '~shared/lib';
import { Amount, Label, Page, Progress, Skeleton, Tabs, TabsContent, TabsList, TabsTrigger } from '~shared/ui';
import { TaskAgreementDrawer } from '~widgets/task-agreement-drawer';
import { TaskDrawer } from '~widgets/task-drawer';

import { useTasks } from '../lib';
import { TaskTabs, TaskTabType } from '../model';

export const TasksPage: React.FC = observer(() => {
  const {
    userStore: { user, getUser },
    projectStore: { project, getProject },
  } = useStore();
  const tasks = useTasks();
  const [selectedTask, setSelectedTask] = useState<UserTaskSchema | UserInviteeTaskSchema | PaymentTaskSchema | null>(
    null
  );
  const [selectedTaskTab, setSelectedTaskTab] = useState<TaskTabType>(TaskTabType.Active);
  const [isAgreementDrawerOpen, setIsAgreementDrawerOpen] = useState(false);

  const linksRefs = useRef<HTMLButtonElement[]>([]);
  const indicatorLeft = useMotionValue(0);
  const indicatorWidth = useMotionValue(0);
  const { width: screenWidth } = useScreenDimensions();

  const { completedTasks, totalTasks, totalCoins } = useMemo(() => {
    const total = tasks[selectedTaskTab]?.results.length ?? 0;
    let completed: number;
    if (selectedTaskTab === TaskTabType.Invitee) {
      completed =
        tasks[selectedTaskTab]?.results.reduce((acc: number, task: UserInviteeTaskSchema) => {
          return acc + (task.state === UserInviteeTaskSchema.state.COMPLETED ? 1 : 0);
        }, 0) ?? 0;
    } else if (selectedTaskTab === TaskTabType.Active) {
      completed =
        tasks[selectedTaskTab]?.results.reduce((acc: number, task: UserTaskSchema | PaymentTaskSchema): number => {
          return (
            acc +
            ((task as UserTaskSchema).is_completed ||
            (task as PaymentTaskSchema).state === PaymentTaskSchema.state.COMPLETED
              ? 1
              : 0)
          );
        }, 0) ?? 0;
    } else {
      completed =
        tasks[selectedTaskTab]?.results.reduce((acc: number, task: UserTaskSchema): number => {
          return acc + (task.is_completed ? 1 : 0);
        }, 0) ?? 0;
    }

    const totalCoins =
      tasks[selectedTaskTab]?.results
        .map((task) => task.amount)
        .reduce((acc: number, amount: string) => {
          return acc + parseFloat(amount);
        }, 0) ?? 0;

    return {
      totalTasks: total,
      completedTasks: completed,
      totalCoins,
    };
  }, [tasks, selectedTaskTab]);

  const handleCompleteTask = useCallback(async () => {
    try {
      if (isNil(user) || isNil(user.id) || isNil(project) || isNil(project.id)) return;
      await getUser();
      await getProject(project.id, user.id);
      setSelectedTask(null);
    } catch (error) {}
  }, [getUser, getProject, project, user]);

  const handleTaskTypeChange = useCallback((newTab: string) => {
    setSelectedTaskTab(newTab as TaskTabType);
  }, []);

  const handleTaskSelect = useCallback(
    (event: React.MouseEvent, task: UserTaskSchema | UserInviteeTaskSchema | PaymentTaskSchema) => {
      if (isDefined((task as PaymentTaskSchema).state) || task.type.id === STORIES_TASKS_TYPE_ID) {
        event.preventDefault();
      }

      if (isDefined(sessionStorage.getItem('taskAgreement'))) {
        setSelectedTask(task);
      } else {
        event.preventDefault();
        setIsAgreementDrawerOpen(true);
      }
    },
    []
  );

  useEffect(() => {
    if (isNil(sessionStorage.getItem('taskAgreement'))) {
      setIsAgreementDrawerOpen(true);
    }
  }, []);

  const selectedTasks = tasks[selectedTaskTab];

  useEffect(() => {
    const tabIndex = TaskTabs.findIndex(({ type }) => type === selectedTaskTab);

    if (tabIndex !== -1 && tabIndex < linksRefs.current.length) {
      indicatorLeft.set(linksRefs.current[tabIndex].offsetLeft + 10);
      indicatorWidth.set(linksRefs.current[tabIndex].clientWidth - 20);
    } else {
      indicatorLeft.set(0);
      indicatorWidth.set(0);
    }
  }, [selectedTaskTab, indicatorLeft, indicatorWidth, screenWidth]);

  return (
    <>
      <Page className="items-center">
        <h1 className="text-center text-lg font-bold uppercase">Tasks</h1>
        <p className="mt-2 text-center text-white/80">Complete tasks and get more coins</p>

        <div className="mt-8 w-full rounded-[10px] border border-border bg-background">
          <div className="p-4">
            {isDefined(tasks[selectedTaskTab]) ? (
              <Label className="font-normal">
                <span
                  className={cn('text-lg font-bold', {
                    'text-progress': completedTasks === 0,
                    'text-yellow': completedTasks > 0 && completedTasks < totalTasks,
                    'text-mint': completedTasks >= totalTasks,
                  })}
                >
                  {completedTasks}
                </span>
                /{totalTasks} completed
              </Label>
            ) : (
              <Skeleton className="h-7 w-[100px]" />
            )}
            {isDefined(tasks[selectedTaskTab]) ? (
              <Progress className="mt-3" value={Math.floor((completedTasks / totalTasks) * 100)} />
            ) : (
              <Skeleton className="mt-3 h-[10px] w-full rounded-full" />
            )}
          </div>
          <hr className="h-px w-full bg-border" />
          <div className="p-4">
            <Label className="font-normal text-white/80">
              {completedTasks < totalTasks ? "Complete all tasks and you'll get:" : 'You earned this amount:'}
            </Label>

            {isDefined(tasks[selectedTaskTab]) ? (
              <Amount
                className="mt-1 justify-start [&>*]:text-lg [&>img]:mr-1 [&>img]:size-6"
                value={formatAmount(totalCoins)}
                logo={project?.logo_url}
              />
            ) : (
              <Skeleton className="mt-1 h-7 w-[120px]" />
            )}
          </div>
        </div>

        <Tabs className="mt-8 flex w-full flex-col" value={selectedTaskTab} onValueChange={handleTaskTypeChange}>
          <TabsList className="relative">
            {TaskTabs.map((tab, index) => (
              <TabsTrigger
                value={tab.type}
                key={tab.type}
                ref={(ref) => {
                  if (isDefined(ref)) {
                    linksRefs.current[index] = ref;
                  }
                }}
              >
                {tab.label}
              </TabsTrigger>
            ))}
            <motion.div
              className="absolute bottom-0 h-px bg-blue transition-all"
              style={{ left: indicatorLeft, width: indicatorWidth }}
            />
          </TabsList>

          {TaskTabs.map((tab) => (
            <TabsContent className="mt-8 flex w-full flex-col space-y-5" value={tab.type} key={tab.type}>
              <h2 className="text-left text-md font-bold">{tab.label}</h2>

              <div className="flex w-full flex-col space-y-2">
                {isNil(selectedTasks) && <Skeleton className="h-[72px] w-full" />}
                {isDefined(selectedTasks) && selectedTasks.count === 0 && <p>No tasks for now</p>}
                {isDefined(selectedTasks) &&
                  selectedTasks.results.map((task) =>
                    selectedTaskTab === TaskTabType.Invitee ? (
                      <InviteeTaskListItem
                        task={task as UserInviteeTaskSchema}
                        onSelect={handleTaskSelect}
                        key={task.id}
                      />
                    ) : (
                      <TaskListItem task={task as UserTaskSchema} onSelect={handleTaskSelect} key={task.id} />
                    )
                  )}
              </div>
            </TabsContent>
          ))}
        </Tabs>
      </Page>

      <TaskDrawer task={selectedTask} setSelectedTask={setSelectedTask} onComplete={handleCompleteTask} />

      <TaskAgreementDrawer isOpen={isAgreementDrawerOpen} onOpenChange={setIsAgreementDrawerOpen} />
    </>
  );
});
