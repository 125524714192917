import React, { useCallback, useState } from 'react';

import { useStore } from 'app/store';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';

import { useToast } from '~features/toaster/lib';
import { type ApiError, WidgetsUsersApiService } from '~shared/api/client';
import { Button, Drawer, DrawerContent, DrawerHeader, DrawerTitle, Input } from '~shared/ui';

interface XAccountDrawerProps {
  isOpen: boolean;
  onClose: (newOpen: boolean) => void;
}

export const XAccountDrawer: React.FC<XAccountDrawerProps> = observer(({ isOpen, onClose }) => {
  const {
    userStore: { user, getUser },
  } = useStore();

  const { toast } = useToast();
  const [xAccount, setXAccount] = useState<string>('');
  const [isConnectLoading, setIsConnectLoading] = useState(false);

  const handleClose = useCallback(
    (newOpen: boolean) => {
      onClose(newOpen);
      setXAccount(user?.twitter_profile ?? '');
    },
    [onClose, user]
  );

  const connectXAccount = useCallback(async () => {
    if (isNil(user) || isNil(user.id)) return;
    try {
      setIsConnectLoading(true);
      await WidgetsUsersApiService.updateTwitterProfileApiV2WidgetUsersUserIdTwitterPut(user.id, {
        twitter_profile: xAccount,
      });
      await getUser();
      toast({
        title: 'X Account Connected',
      });
      handleClose(false);
    } catch (error) {
      toast({
        title:
          ((error as ApiError)?.body as { detail: { msg: string } })?.detail?.msg ??
          ((error as ApiError)?.body as { detail: Array<{ msg: string }> })?.detail?.[0]?.msg ??
          'Unknown error',
      });
    } finally {
      setIsConnectLoading(false);
    }
  }, [user, xAccount, toast, handleClose, getUser]);

  return (
    <Drawer open={isOpen} onOpenChange={handleClose}>
      <DrawerContent>
        <DrawerHeader className="flex flex-col items-center">
          <DrawerTitle>Connect Account</DrawerTitle>
        </DrawerHeader>

        <Input
          value={xAccount}
          placeholder="@username"
          className="mt-6"
          onChange={(event) => {
            setXAccount(event.target.value);
          }}
        />

        <Button
          className="mt-4"
          buttonColor="green"
          disabled={xAccount.length === 0}
          onClick={connectXAccount}
          isLoading={isConnectLoading}
        >
          Connect
        </Button>
      </DrawerContent>
    </Drawer>
  );
});
