/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* eslint-disable */
import type { GetTransactionResponse } from '../models/GetTransactionResponse';
import type { TransactionTaskCaptureSchema } from '../models/TransactionTaskCaptureSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WidgetsTransactionsApiService {
  /**
   * Task Capture Transaction
   * Capture transaction for task
   * @param userId
   * @param requestBody
   * @returns GetTransactionResponse Successful Response
   * @throws ApiError
   */
  public static taskCaptureTransactionApiV2WidgetUsersUserIdTransactionsTaskCapturePost(
    userId: string,
    requestBody: TransactionTaskCaptureSchema
  ): CancelablePromise<GetTransactionResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v2/widget/users/{user_id}/transactions/task_capture',
      path: {
        user_id: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
