/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* eslint-disable */
import type { GetInviteeTasksListResponse } from '../models/GetInviteeTasksListResponse';
import type { GetPaymentTasksListResponse } from '../models/GetPaymentTasksListResponse';
import type { GetUserTasksListResponse } from '../models/GetUserTasksListResponse';
import type { UserTaskSchema } from '../models/UserTaskSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WidgetsTasksApiService {
  /**
   * Get Static
   * Get static tasks
   * @param userId
   * @param projectId
   * @returns GetUserTasksListResponse Successful Response
   * @throws ApiError
   */
  public static getStaticApiV2WidgetUsersUserIdProjectsProjectIdStaticTasksGet(
    userId: string,
    projectId: string
  ): CancelablePromise<GetUserTasksListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v2/widget/users/{user_id}/projects/{project_id}/static_tasks',
      path: {
        user_id: userId,
        project_id: projectId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Active
   * Get active tasks (daily, weekly, etc.)
   * @param userId
   * @param projectId
   * @returns GetUserTasksListResponse Successful Response
   * @throws ApiError
   */
  public static getActiveApiV2WidgetUsersUserIdProjectsProjectIdActiveTasksGet(
    userId: string,
    projectId: string
  ): CancelablePromise<GetUserTasksListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v2/widget/users/{user_id}/projects/{project_id}/active_tasks',
      path: {
        user_id: userId,
        project_id: projectId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Invitee
   * Get tasks for invitee
   * @param userId
   * @returns GetInviteeTasksListResponse Successful Response
   * @throws ApiError
   */
  public static getInviteeApiV2WidgetUsersUserIdInviteeTasksGet(
    userId: string
  ): CancelablePromise<GetInviteeTasksListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v2/widget/users/{user_id}/invitee_tasks',
      path: {
        user_id: userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Payments
   * Get tasks for payment
   * @param userId
   * @returns GetPaymentTasksListResponse Successful Response
   * @throws ApiError
   */
  public static getPaymentsApiV2WidgetUsersUserIdPaymentsTasksGet(
    userId: string
  ): CancelablePromise<GetPaymentTasksListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v2/widget/users/{user_id}/payments_tasks',
      path: {
        user_id: userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Complete
   * Complete the task
   *
   * RateLimit: 1 request in 5 seconds
   * @param taskId
   * @param userId
   * @param projectId
   * @returns UserTaskSchema Successful Response
   * @throws ApiError
   */
  public static completeApiV2WidgetUsersUserIdProjectsProjectIdTasksTaskIdCompletedPost(
    taskId: string,
    userId: string,
    projectId: string
  ): CancelablePromise<UserTaskSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v2/widget/users/{user_id}/projects/{project_id}/tasks/{task_id}/completed',
      path: {
        task_id: taskId,
        user_id: userId,
        project_id: projectId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
