/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* eslint-disable */
import type { GetDailyRewardSchema } from '../models/GetDailyRewardSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WidgetsDailyRewardsApiService {
  /**
   * Get Daily Rewards
   * @param userId
   * @returns GetDailyRewardSchema Successful Response
   * @throws ApiError
   */
  public static getDailyRewardsApiV2WidgetUsersUserIdDailyRewardsGet(
    userId: string
  ): CancelablePromise<GetDailyRewardSchema> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v2/widget/users/{user_id}/daily_rewards',
      path: {
        user_id: userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Claim
   * @param userId
   * @returns GetDailyRewardSchema Successful Response
   * @throws ApiError
   */
  public static claimApiV2WidgetUsersUserIdDailyRewardsClaimPost(
    userId: string
  ): CancelablePromise<GetDailyRewardSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v2/widget/users/{user_id}/daily_rewards/claim',
      path: {
        user_id: userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
