import React, { useCallback } from 'react';

import { useStore } from 'app/store';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';
import { Link, generatePath } from 'react-router-dom';

import { RefQr } from '~features/ref-qr';
import { PeopleIcon } from '~shared/assets/icons';
import { formatAmount, isDefined } from '~shared/lib';
import { AppRoutes } from '~shared/model';
import { Amount, AnimatedCounter, Button, Hedgehog, Page, Skeleton } from '~shared/ui';

import { useBonuses, useStructureCount } from '../lib';

export const FriendsPage: React.FC = observer(() => {
  const {
    projectStore: { project },
    userStore: { openShareDrawer },
  } = useStore();
  const { bonuses, minutesRemaining, handleClaimBonuses, isClaimLoading } = useBonuses();
  const structureCount = useStructureCount();

  const handleShareDrawerOpen = useCallback(() => {
    Telegram.WebApp.HapticFeedback.notificationOccurred('success');
    openShareDrawer();
  }, [openShareDrawer]);

  return (
    <Page>
      <h1 className="text-center text-lg font-bold uppercase">Invite Friends</h1>
      <p className="mt-2 text-center text-white/80">
        Get <b className="text-white">10%</b> from your friends <b className="text-white">+5%</b> from their referrals{' '}
        <b className="text-white">+2.5%</b> from their referrals
      </p>

      <div className="mt-5 flex w-full flex-col items-center justify-center space-y-6">
        {isDefined(bonuses) ? (
          <Amount
            className="[&>*]:text-4xl [&>img]:mr-1 [&>img]:size-[45px]"
            value={formatAmount(parseFloat(bonuses.amount))}
            logo={project?.logo_url}
          />
        ) : (
          <Skeleton className="h-[50px] w-[200px]" />
        )}

        {isDefined(bonuses) && bonuses.can_clime && (
          <Button
            className="rounded-[10px] px-3 py-2 text-md font-normal"
            buttonColor="green"
            onClick={handleClaimBonuses}
            isLoading={isClaimLoading}
          >
            Claim
          </Button>
        )}

        {isDefined(bonuses) && !bonuses.can_clime && (
          <Button className="rounded-[10px] px-3 py-2 text-md font-normal" buttonColor="red" disabled>
            Claim{' '}
            {minutesRemaining > 0 && (
              <>
                in <AnimatedCounter className="ml-[0.5ch]" value={Math.trunc(minutesRemaining / 60).toString()} />
                h <AnimatedCounter className="ml-[0.5ch]" value={(minutesRemaining % 60).toString()} />m
              </>
            )}
          </Button>
        )}

        {isNil(bonuses) && <Skeleton className="h-10 w-[120px]" />}
      </div>

      <div className="my-10 flex flex-col">
        <div className="grid w-full grid-cols-3">
          <Button asChild variant="wrapper" size="wrapper">
            <Link className="flex flex-col items-center" to={generatePath(AppRoutes.LevelFriends, { level: '2' })}>
              {isDefined(project) && project?.is_root ? (
                <Hedgehog className="mt-auto w-[90px]" color="green" />
              ) : isDefined(project) ? (
                <img className="mt-auto size-[90px] overflow-hidden rounded-full" src={project.logo_url ?? ''} alt="" />
              ) : (
                <Skeleton className="mt-auto size-[90px] rounded-full" />
              )}
              <span className="mt-auto text-xs font-semibold uppercase">2 lvl</span>
              {isDefined(structureCount) ? (
                <div className="mt-1 flex items-center">
                  <PeopleIcon className="mr-1 size-6 text-white/50" />
                  <span className="text-xs">{structureCount.level2}</span>
                </div>
              ) : (
                <Skeleton className="mt-1 h-6 w-[54px]" />
              )}
            </Link>
          </Button>

          <Button asChild variant="wrapper" size="wrapper">
            <Link className="flex flex-col items-center" to={generatePath(AppRoutes.LevelFriends, { level: '1' })}>
              {isDefined(project) && project?.is_root ? (
                <Hedgehog className="mt-auto w-[100px]" color="red" />
              ) : isDefined(project) ? (
                <img
                  className="mt-auto size-[100px] overflow-hidden rounded-full"
                  src={project.logo_url ?? ''}
                  alt=""
                />
              ) : (
                <Skeleton className="mt-auto size-[100px] rounded-full" />
              )}
              <span className="mt-auto text-xs font-semibold uppercase">1 lvl</span>
              {isDefined(structureCount) ? (
                <div className="mt-1 flex items-center">
                  <PeopleIcon className="mr-1 size-6 text-white/50" />
                  <span className="text-xs">{structureCount.level1}</span>
                </div>
              ) : (
                <Skeleton className="mt-1 h-6 w-[54px]" />
              )}
            </Link>
          </Button>

          <Button asChild variant="wrapper" size="wrapper">
            <Link className="flex flex-col items-center" to={generatePath(AppRoutes.LevelFriends, { level: '3' })}>
              {isDefined(project) && project?.is_root ? (
                <Hedgehog className="mt-auto w-[80px]" color="blue" />
              ) : isDefined(project) ? (
                <img className="mt-auto size-[80px] overflow-hidden rounded-full" src={project.logo_url ?? ''} alt="" />
              ) : (
                <Skeleton className="mt-auto size-[80px] rounded-full" />
              )}
              <span className="mt-auto text-xs font-semibold uppercase">3 lvl</span>
              {isDefined(structureCount) ? (
                <div className="mt-1 flex items-center">
                  <PeopleIcon className="mr-1 size-6 text-white/50" />
                  <span className="text-xs">{structureCount.level3}</span>
                </div>
              ) : (
                <Skeleton className="mt-1 h-6 w-[54px]" />
              )}
            </Link>
          </Button>
        </div>

        {isDefined(structureCount) && structureCount.level1 + structureCount.level2 + structureCount.level3 === 0 && (
          <p className="mt-4 text-center text-red">No friends yet</p>
        )}
      </div>

      <RefQr
        className="my-6"
        qrHexColor="#246FF2"
        sphereColorClassName="bg-green-glowing-sphere"
        usernameColorClassName="text-blue"
      />

      <Button className="mt-auto" buttonColor="blue" onClick={handleShareDrawerOpen}>
        Invite a friends
      </Button>
    </Page>
  );
});
