import autofarmingBoostHedgehogImage from './_autofarming-boost-hedgehog.png';
import blueHedgehogImage from './_blue-hedgehog.png';
import dailyRewardBoostHedgehogImage from './_daily-reward-boost-hedgehog.png';
import farmingBoostHedgehogImage from './_farming-boost-hedgehog.png';
import greenHedgehogImage from './_green-hedgehog.png';
import hedgehogsImage from './_hedgehogs.png';
import redHedgehogImage from './_red-hedgehog.png';
import subscribeDrawerHedgehogImage from './_subscribe-drawer-hedgehog.png';
import topBoostHedgehogImage from './_top-boost-hedgehog.png';

export const HEDGEHOGS_COLOR_MAP = {
  red: redHedgehogImage,
  blue: blueHedgehogImage,
  green: greenHedgehogImage,
  white: blueHedgehogImage,
} as const;

export {
  autofarmingBoostHedgehogImage,
  dailyRewardBoostHedgehogImage,
  farmingBoostHedgehogImage,
  topBoostHedgehogImage,
  blueHedgehogImage,
  greenHedgehogImage,
  redHedgehogImage,
  hedgehogsImage,
  subscribeDrawerHedgehogImage,
};
