/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* eslint-disable */
import type { TaskCategory } from './TaskCategory';
import type { TaskTypeSchema } from './TaskTypeSchema';
export type UserInviteeTaskSchema = {
  id?: string;
  name: string;
  description: string;
  amount: string;
  count: number;
  state?: UserInviteeTaskSchema.state;
  type: TaskTypeSchema;
  category: TaskCategory;
};
export namespace UserInviteeTaskSchema {
  export enum state {
    NOT_READY = 'not_ready',
    READY = 'ready',
    COMPLETED = 'completed',
  }
}
