import React from 'react';

import { Link } from 'react-router-dom';

import { FortuneSpinPreview } from '~shared/assets/images/fortune-spin';
import { AppRoutes } from '~shared/model';
import { Button, Page } from '~shared/ui';

export const GamesPage: React.FC = () => {
  return (
    <Page>
      <h1 className="text-center text-lg font-bold">Games</h1>
      <p className="mt-2 text-center text-white/80">Play to earn more points</p>

      <div className="mt-8 flex flex-col space-y-3">
        <div className="flex flex-col overflow-hidden rounded-xl">
          <div className="flex h-[134px] flex-col items-center bg-game-card">
            <FortuneSpinPreview className="h-full w-auto" />
          </div>
          <div className="flex flex-col items-center bg-white/10 px-6 py-4 text-center backdrop-blur-lg">
            <h3 className="text-base font-bold">Fortune Spin</h3>
            <p className="mt-1">Earn your luck with a spin and secure rewards like points, tokens, and lives!</p>
            <Button className="mt-6 w-full max-w-[160px] p-1 text-base font-normal" buttonColor="blue" asChild>
              <Link to={AppRoutes.FortuneSpin}>Play</Link>
            </Button>
          </div>
        </div>
      </div>
    </Page>
  );
};
