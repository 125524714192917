import type React from 'react';

import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { BoostPageTab } from '~entities/boost';
import { ArrowRightIcon } from '~shared/assets/icons/arrows';
import tgStar from '~shared/assets/images/_tg-star.png';
import { AppRoutes } from '~shared/model';
import { Button } from '~shared/ui';

interface BoostButtonProps extends React.PropsWithChildren {
  className?: string;
  tab?: BoostPageTab;
}

export const BoostButton: React.FC<BoostButtonProps> = ({ className, tab, children }) => {
  return (
    <Button
      className={twMerge('rounded-xl border border-white/10 px-4 py-2 text-md font-normal', className)}
      variant="wrapper"
      size="wrapper"
      asChild
    >
      <Link to={AppRoutes.Boost} state={{ tab: tab ?? BoostPageTab.Farming }}>
        <img className="mr-2 size-6" src={tgStar} alt="" />
        {children}
        <ArrowRightIcon className="ml-2 size-6" />
      </Link>
    </Button>
  );
};
