export enum AirDropTabType {
  Active = 'active',
  Planned = 'planned',
  Completed = 'completed',
}

export const AirDropTabs = [
  {
    type: AirDropTabType.Active,
    label: 'Active',
  },
  {
    type: AirDropTabType.Planned,
    label: 'Planned',
  },
  {
    type: AirDropTabType.Completed,
    label: 'Completed',
  },
];
