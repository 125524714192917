import { useStore } from 'app/store';

import { formatAmount, isDefined } from '~shared/lib';

export type UserBalanceInfo = {
  balance: number | null;
  formatedBalance: string | null;
  logo: string | null;
};

export const useUserBalance = (): UserBalanceInfo | null => {
  const {
    projectStore: { project },
  } = useStore();

  return isDefined(project)
    ? {
        balance: isDefined(project.balance) ? parseFloat(project.balance) : 0,
        formatedBalance: formatAmount(isDefined(project.balance) ? parseFloat(project.balance) : 0),
        logo: project.is_root ? null : project.logo_url ?? null,
      }
    : null;
};
