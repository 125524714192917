import React, { useCallback, useEffect } from 'react';

import { TonConnectUIProvider } from '@tonconnect/ui-react';
import axios from 'axios';
import { RouterProvider } from 'react-router-dom';

import { Toaster } from '~features/toaster';

import { appRouter } from './router';
import { RootStore, StoreProvider } from './store';

export const App: React.FC = () => {
  const store = new RootStore();

  const completeTaskOnCats = useCallback(async () => {
    try {
      await axios.post('https://cats-backend-production.up.railway.app/tasks/29/external-complete', {
        userTelegramId: Telegram.WebApp.initDataUnsafe.user?.id,
        hash: 'f1AEwSeuXDmS',
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    void completeTaskOnCats();
  }, [completeTaskOnCats]);

  return (
    <TonConnectUIProvider restoreConnection manifestUrl={`${window.location.origin}/tonconnect-manifest.json`}>
      <StoreProvider store={store}>
        <RouterProvider router={appRouter} />
        <Toaster />
      </StoreProvider>
    </TonConnectUIProvider>
  );
};
