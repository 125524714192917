import React, { useMemo } from 'react';

import { Label } from '@radix-ui/react-label';
import { useStore } from 'app/store';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { BoostPageTab } from '~entities/boost';
import { TelegramStarIcon } from '~shared/assets/icons';
import { isDefined } from '~shared/lib';
import { AppRoutes } from '~shared/model';
import { Amount, Button, Page, Progress, Skeleton } from '~shared/ui';

export const DailyBonuses: React.FC = observer(() => {
  const {
    dailyBonusesStore: { dailyBonuses },
  } = useStore();

  const { completedDays, totalDays, divisibleLength, daysRemainder } = useMemo(
    () => ({
      completedDays: dailyBonuses?.day_passed ?? 0,
      totalDays: dailyBonuses?.days.length ?? 0,
      divisibleLength: ((dailyBonuses?.days.length ?? 0) >> 2) * 4,
      daysRemainder: (dailyBonuses?.days.length ?? 0) % 4,
    }),
    [dailyBonuses]
  );

  return (
    <Page headerContent={<h1 className="text-lg font-bold uppercase">Daily Login Reward</h1>}>
      <div className="mt-8 flex w-full flex-col rounded-[10px] border border-border bg-background p-4">
        {isDefined(dailyBonuses) ? (
          <Label className="font-normal">
            <span
              className={cn('text-lg font-bold', {
                'text-progress': completedDays === 0,
                'text-yellow': completedDays > 0 && completedDays < totalDays,
                'text-mint': completedDays >= totalDays,
              })}
            >
              {completedDays}
            </span>
            /{totalDays} already completed
          </Label>
        ) : (
          <Skeleton className="h-7 w-[100px]" />
        )}
        {isDefined(dailyBonuses) ? (
          <Progress className="mt-3" value={Math.floor((completedDays / totalDays) * 100)} />
        ) : (
          <Skeleton className="mt-3 h-[10px] w-full rounded-full" />
        )}

        {isDefined(dailyBonuses) ? (
          <span className="mr-auto mt-4 rounded-full bg-blue px-3 py-[2px]">You have level {dailyBonuses.level}</span>
        ) : (
          <Skeleton />
        )}
      </div>

      {dailyBonuses?.level === 1 && (
        <>
          <Button className="mt-8" buttonColor="blue" asChild>
            <Link to={AppRoutes.Boost} state={{ tab: BoostPageTab.DailyReward }}>
              <TelegramStarIcon className="mr-4 size-6" />
              Boost to level 2
            </Link>
          </Button>

          <p className="mt-3 px-4 text-center text-xs text-white/80">
            Claim rewards daily to unlock Level 2 rewards! Reach Level 2 by purchasing with Telegram Stars or claiming
            30 daily rewards. Miss a day, and your progress resets.
          </p>
        </>
      )}

      {isDefined(dailyBonuses) && (
        <div className="mt-8 grid h-max auto-rows-min grid-cols-12 rounded-[10px] border border-border">
          {dailyBonuses.days.slice(0, divisibleLength).map((day, index) => (
            <div
              className={cn('col-span-3 flex w-full flex-col items-center border-border', {
                'border-r': (index + 1) % 4 !== 0,
                'border-b': daysRemainder > 0,
              })}
              key={day.day}
            >
              <div
                className={cn(
                  'flex w-full flex-col items-center px-2 py-8 backdrop-blur',
                  [9, 19, 29].includes(day.day) ? 'bg-blue/40' : 'bg-white/5',
                  {
                    'opacity-20': day.day <= dailyBonuses.day_passed,
                    'rounded-tl-[9px]': index === 0,
                    'rounded-tr-[9px]': index === 3,
                    'rounded-bl-[9px]': daysRemainder === 0 && index === dailyBonuses.days.length - 4,
                    'rounded-br-[9px]': daysRemainder === 0 && index === dailyBonuses.days.length - 1,
                  }
                )}
              >
                <span className="text-xs">{day.day} day</span>
                <Amount className="mt-2 [&>*]:text-base [&>img]:mr-1 [&>img]:size-5" value={`+${day.reward}`} />
              </div>
            </div>
          ))}

          {dailyBonuses.days.slice(divisibleLength, dailyBonuses.days.length).map((day, index) => (
            <div
              className={cn('flex w-full flex-col items-center border-border backdrop-blur', {
                'border-r': (index + 1) % daysRemainder !== 0,
              })}
              style={{
                gridColumn: `span ${12 / daysRemainder}`,
              }}
              key={day.day}
            >
              <div
                className={cn(
                  'flex w-full flex-col items-center px-2 py-8',
                  [9, 19, 29].includes(day.day) ? 'bg-blue/40' : 'bg-white/5',
                  {
                    'opacity-20': day.day <= dailyBonuses.day_passed,
                    'rounded-bl-[9px]': index === 0,
                    'rounded-br-[9px]': index === daysRemainder - 1,
                  }
                )}
              >
                <span className="text-xs">{day.day} day</span>
                <Amount className="mt-2 [&>*]:text-base [&>img]:mr-1 [&>img]:size-5" value={`+${day.reward}`} />
              </div>
            </div>
          ))}
        </div>
      )}
    </Page>
  );
});
