import React, { useEffect, useRef, useState } from 'react';

import { useStore } from 'app/store';
import cn from 'classnames';
import * as dateFns from 'date-fns';
import { motion } from 'framer-motion';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';
import { twMerge } from 'tailwind-merge';

import { AUTO_FARMING_LIFE_TIME_MAP } from '~entities/boost';
import { useLatestJob } from '~entities/job';
import { JobStatus } from '~shared/api/client';
import {
  CoinStack1,
  CoinStack10,
  CoinStack11,
  CoinStack12,
  CoinStack13,
  CoinStack14,
  CoinStack15,
  CoinStack16,
  CoinStack2,
  CoinStack3,
  CoinStack4,
  CoinStack5,
  CoinStack6,
  CoinStack7,
  CoinStack8,
  CoinStack9,
} from '~shared/assets/images/walking-hedgehog-frames';
import { isDefined, useScreenDimensions } from '~shared/lib';
import { Skeleton } from '~shared/ui';

const coinsStacksProgress = [
  CoinStack1,
  CoinStack2,
  CoinStack3,
  CoinStack4,
  CoinStack5,
  CoinStack6,
  CoinStack7,
  CoinStack8,
  CoinStack9,
  CoinStack10,
  CoinStack11,
  CoinStack12,
  CoinStack13,
  CoinStack14,
  CoinStack15,
  CoinStack16,
];

export interface CoinStackAnimationProps {
  className?: string;
}

export const CoinStackAnimation: React.FC<CoinStackAnimationProps> = observer(({ className }) => {
  const {
    projectStore: { project },
  } = useStore();
  const { latestJob, loaded } = useLatestJob();
  const jobStatus = latestJob?.status ?? null;
  const { width: screenWidth } = useScreenDimensions();

  const timerRef = useRef<NodeJS.Timer>();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    clearInterval(timerRef.current);
    const handleTimerUpdate = (): void => {
      const secondsRemaning = dateFns.differenceInSeconds(
        isDefined(latestJob) ? new Date(`${latestJob.end_period}Z`) : new Date(),
        new Date()
      );
      const autoFarmingLifetime =
        isDefined(latestJob) && isDefined(latestJob.auto_farming_purchased_id)
          ? AUTO_FARMING_LIFE_TIME_MAP[latestJob.auto_farming_purchased_id as keyof typeof AUTO_FARMING_LIFE_TIME_MAP]
          : null;

      setProgress(
        Math.min(isDefined(project) ? 1 - secondsRemaning / (autoFarmingLifetime ?? project.lifetime) : 0, 1)
      );
    };

    handleTimerUpdate();
    timerRef.current = setInterval(handleTimerUpdate, 4000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [timerRef, latestJob, project]);

  const LeftCoinStack = coinsStacksProgress[Math.ceil((coinsStacksProgress.length - 1) * (1 - progress))];
  const RightCoinStack = coinsStacksProgress[Math.trunc((coinsStacksProgress.length - 1) * progress)];

  return (
    <div className={twMerge('relative flex h-[128px] w-full shrink-0 items-end justify-between', className)}>
      {!loaded && <Skeleton className="size-full" />}

      {loaded && (
        <>
          <div className="flex h-[87px] w-[85px] items-end justify-start">
            {jobStatus === JobStatus.PROCESSING && progress < 1 && <LeftCoinStack />}
            {((isNil(jobStatus) && loaded) || jobStatus === JobStatus.COMPLETED) && <CoinStack16 />}
          </div>

          <motion.div
            className={cn('absolute h-[128px] w-[142px] bg-no-repeat', {
              'bg-contain': jobStatus !== JobStatus.PROCESSING,
              'bg-[length:auto_896px]': jobStatus === JobStatus.PROCESSING,
              'animate-hedgehog-walking': jobStatus === JobStatus.PROCESSING && project?.farming_up_level === 1,
              'animate-hedgehog-walking-x2': jobStatus === JobStatus.PROCESSING && (project?.farming_up_level ?? 0) > 1,
              'left-[53px] bg-walking-hedgehog-stale -scale-x-100':
                (isNil(jobStatus) && loaded) || jobStatus === JobStatus.COMPLETED,
              'right-[53px] bg-walking-hedgehog-stale': jobStatus === JobStatus.READY,
            })}
            animate={{
              left: jobStatus === JobStatus.PROCESSING ? [53, screenWidth - 142 - 53 - 48, 53] : undefined,
            }}
            transition={{ ease: 'linear', duration: 8, repeat: Infinity }}
            key={`${jobStatus}-${screenWidth}`}
          />

          <div className="flex h-[87px] w-[85px] items-end justify-start">
            {jobStatus === JobStatus.PROCESSING && progress > 0 && <RightCoinStack />}
            {jobStatus === JobStatus.READY && <CoinStack16 />}
          </div>
        </>
      )}
    </div>
  );
});
