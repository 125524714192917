import type React from 'react';
import { useCallback } from 'react';

import { useStore } from 'app/store';
import { observer } from 'mobx-react';

import { useActiveBoostTimer } from '~entities/boost/lib/use-active-boost-timer.hook';
import { autofarmingBoostHedgehogImage } from '~shared/assets/images/hedgehogs';
import { isDefined } from '~shared/lib';
import {
  AnimatedCounter,
  Button,
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '~shared/ui';

export const AutoFarmingBoostActivatedDrawer: React.FC = observer(() => {
  const {
    jobStore: { latestJob },
    boostsStore: { autoFarmingBoostActivatedDrawerProps, closeAutoFarmingBoostActivatedDrawer },
  } = useStore();

  const { timerValues } = useActiveBoostTimer(
    autoFarmingBoostActivatedDrawerProps?.boost ?? null,
    isDefined(latestJob) && isDefined(latestJob.auto_farming_purchased_id) ? latestJob.end_period : null,
    latestJob?.auto_farming_purchased_id
  );

  const handleOpenChange = useCallback(
    (newOpen: boolean) => {
      if (!newOpen) {
        closeAutoFarmingBoostActivatedDrawer();
      }
    },
    [closeAutoFarmingBoostActivatedDrawer]
  );

  return (
    <Drawer open={isDefined(autoFarmingBoostActivatedDrawerProps)} onOpenChange={handleOpenChange}>
      <DrawerContent hideCloseButton>
        <DrawerHeader className="mb-0 flex flex-col items-center">
          <div className="relative">
            <img className="h-auto w-[187px]" src={autofarmingBoostHedgehogImage} alt="" />
            <Button
              className="absolute bottom-[-22px] left-1/2 w-full max-w-[200px] -translate-x-1/2 p-2 text-base font-normal"
              buttonColor="green"
            >
              <span className="flex h-[18px] items-center text-white/80">
                left <AnimatedCounter className="ml-[1ch] font-bold text-white" value={timerValues.days} />d{' '}
                <AnimatedCounter className="ml-[1ch] font-bold text-white" value={timerValues.hours} />
                h <AnimatedCounter className="ml-[1ch] font-bold text-white" value={timerValues.minutes} />
                min
              </span>
            </Button>
          </div>
          <DrawerTitle className="mt-14 leading-snug">
            Auto-farming
            <br />
            activated!
          </DrawerTitle>
          <DrawerDescription className="mt-4 text-base text-white">
            You have successfully enabled auto-farming for {autoFarmingBoostActivatedDrawerProps?.boost.days} days.
            Enjoy the passive income!
          </DrawerDescription>
        </DrawerHeader>

        <DrawerFooter className="mt-8 gap-4">
          <Button className="h-[60px]" buttonColor="blue" onClick={closeAutoFarmingBoostActivatedDrawer}>
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
});
