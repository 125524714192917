import React, { useCallback } from 'react';

import { type GetAirdropResponse } from '~shared/api/client';
import { isDefined } from '~shared/lib';
import { Drawer, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle } from '~shared/ui';

interface AirdropDescriptionDrawerProps {
  airdrop: GetAirdropResponse | null;
  onClose: () => void;
}

const AirdropDescriptionDrawer: React.FC<AirdropDescriptionDrawerProps> = React.memo(({ airdrop, onClose }) => {
  const handleClose = useCallback(
    (newOpen: boolean) => {
      if (!newOpen) {
        onClose();
      }
    },
    [onClose]
  );

  return (
    <Drawer open={isDefined(airdrop)} onOpenChange={handleClose} shouldScaleBackground>
      <DrawerContent className="flex flex-col pb-0">
        <div className="flex h-min flex-1 flex-col overflow-auto pb-12">
          <DrawerHeader className="flex flex-col items-center">
            <img className="mb-3 size-[100px] overflow-hidden rounded-full" src={airdrop?.image_url ?? ''} alt="" />

            <DrawerTitle>{airdrop?.title}</DrawerTitle>

            <DrawerDescription>
              {Math.floor(new Date().getTime() / 1000) > (airdrop?.end_at ?? 0)
                ? airdrop?.text_after_ended
                : airdrop?.text}
            </DrawerDescription>
          </DrawerHeader>
        </div>
      </DrawerContent>
    </Drawer>
  );
});
AirdropDescriptionDrawer.displayName = 'AirdropDescriptionDrawer';

export { AirdropDescriptionDrawer };
