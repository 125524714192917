import { useCallback, useEffect } from 'react';

import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { useStore } from 'app/store';
import { isNil } from 'lodash';

import { isDefined } from '~shared/lib';

export const useUserWallet = (): void => {
  const address = useTonAddress(true);

  const [tonConnectUI] = useTonConnectUI();

  const {
    userStore: { user, updateUserWallet },
  } = useStore();

  const handleDisconnect = useCallback(
    async (userId: string) => {
      try {
        await tonConnectUI.disconnect();
        await updateUserWallet(userId, null);
      } catch (error) {
        console.error();
      }
    },
    [tonConnectUI, updateUserWallet]
  );

  useEffect(() => {
    if (isDefined(user) && isDefined(user.id) && isNil(user.ton_wallet_address) && address.length > 0) {
      void updateUserWallet(user.id, address);
    } else if (isDefined(user) && isDefined(user.id) && user.ton_wallet_address !== address) {
      void handleDisconnect(user.id);
    }
  }, [address, user, updateUserWallet, handleDisconnect]);
};
