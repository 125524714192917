import React, { useEffect, useRef, useState } from 'react';

import { useStore } from 'app/store';
import cn from 'classnames';
import * as dateFns from 'date-fns';
import { observer } from 'mobx-react';
import { twMerge } from 'tailwind-merge';

import { AUTO_FARMING_LIFE_TIME_MAP } from '~entities/boost';
import { useLatestJob } from '~entities/job';
import coinImage from '~shared/assets/images/_coin.png';
import { formatAmount, isDefined } from '~shared/lib';
import { Button } from '~shared/ui';
import { AnimatedCounter } from '~shared/ui/animated-counter';

export interface JobProgressButtonProps {
  className?: string;
}

export const JobProgressButton: React.FC<JobProgressButtonProps> = observer(({ className }) => {
  const { latestJob } = useLatestJob();
  const {
    jobStore: { getLatestJob },
    userStore: { user },
    projectStore: { project },
  } = useStore();

  const timerRef = useRef<NodeJS.Timer>();
  const [amount, setAmount] = useState('0');
  const [minutesRemaining, setMinutesRemaining] = useState(0);

  useEffect(() => {
    clearInterval(timerRef.current);
    const handleTimerUpdate = (): void => {
      const secondsRemaning = dateFns.differenceInSeconds(
        isDefined(latestJob) ? new Date(latestJob.end_period) : new Date(),
        dateFns.addMinutes(new Date(), new Date().getTimezoneOffset())
      );

      if (secondsRemaning <= 0) {
        void getLatestJob(project?.id ?? '', user?.id ?? '');
      }

      const autoFarmingLifetime =
        isDefined(latestJob) && isDefined(latestJob.auto_farming_purchased_id)
          ? AUTO_FARMING_LIFE_TIME_MAP[latestJob.auto_farming_purchased_id as keyof typeof AUTO_FARMING_LIFE_TIME_MAP]
          : null;

      setMinutesRemaining(Math.trunc(secondsRemaning / 60));
      setAmount(
        formatAmount(
          Math.round(latestJob?.amount ?? 0) * (1 - secondsRemaning / (autoFarmingLifetime ?? project?.lifetime ?? 1))
        )
      );
    };

    handleTimerUpdate();
    timerRef.current = setInterval(handleTimerUpdate, 5000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [timerRef, latestJob, project, user, getLatestJob]);

  return (
    <Button
      className={twMerge('relative w-full px-16', className)}
      buttonColor="red"
      style={{
        background: `linear-gradient(to right, rgb(230 85  86 / 50%) 0%, rgb(230 85  86 / 50%) ${100 - (minutesRemaining / ((project?.lifetime ?? 0) / 60)) * 100}%, #E65556 ${100 - (minutesRemaining / ((project?.lifetime ?? 0) / 60)) * 100}%, #E65556 100%)`,
      }}
    >
      Farming{' '}
      <span className="ml-2 flex h-full items-center font-bold">
        {isDefined(project) && (
          <img
            className={cn('mr-1 size-6', { 'overflow-hidden rounded-full': !project.is_root })}
            src={project.is_root ? coinImage : project.logo_url ?? ''}
            alt=""
          />
        )}
        <AnimatedCounter className="-my-4 h-[calc(100%_+_32px)]" value={amount} />
      </span>
      <span className="absolute right-2 top-1/2 flex h-full -translate-y-1/2 items-center text-xs text-white/60">
        <AnimatedCounter value={Math.trunc(minutesRemaining / 60).toString()} />h{' '}
        <AnimatedCounter className="ml-[1ch]" value={(minutesRemaining % 60).toString()} />m
      </span>
    </Button>
  );
});
