/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* eslint-disable */
export enum PaymentProduct {
  TOP_1 = 'TOP_1',
  TOP_2 = 'TOP_2',
  TOP_3 = 'TOP_3',
  TOP_4 = 'TOP_4',
  TOP_5 = 'TOP_5',
  TOP_6 = 'TOP_6',
  FARMING_X2_3DAYS = 'FARMING_X2_3DAYS',
  FARMING_X2_9DAYS = 'FARMING_X2_9DAYS',
  FARMING_X2_29DAYS = 'FARMING_X2_29DAYS',
  AUTO_FARMING_3DAYS = 'AUTO_FARMING_3DAYS',
  AUTO_FARMING_9DAYS = 'AUTO_FARMING_9DAYS',
  AUTO_FARMING_30DAYS = 'AUTO_FARMING_30DAYS',
  DAILY_REWARD_X2_30DAYS = 'DAILY_REWARD_X2_30DAYS',
  DAILY_REWARD_X3_30DAYS = 'DAILY_REWARD_X3_30DAYS',
  LIVES_5_PIECES = 'LIVES_5_PIECES',
  LIVES_99_PIECES = 'LIVES_99_PIECES',
  LIVES_499_PIECES = 'LIVES_499_PIECES',
  LIVES_999_PIECES = 'LIVES_999_PIECES',
}
