/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* eslint-disable */
import type { GetPrizesListResponse } from '../models/GetPrizesListResponse';
import type { WinPrizeResponse } from '../models/WinPrizeResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WidgetsFortuneApiService {
  /**
   * Get Prizes List
   * @returns GetPrizesListResponse Successful Response
   * @throws ApiError
   */
  public static getPrizesListApiV2WidgetFortunePrizesGet(): CancelablePromise<GetPrizesListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v2/widget/fortune/prizes',
    });
  }
  /**
   * Make Spin
   * @param userId
   * @returns WinPrizeResponse Successful Response
   * @throws ApiError
   */
  public static makeSpinApiV2WidgetUsersUserIdFortuneMakeSpinPost(userId: string): CancelablePromise<WinPrizeResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v2/widget/users/{user_id}/fortune/make_spin',
      path: {
        user_id: userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
