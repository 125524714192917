import React, { forwardRef } from 'react';

import cn from 'classnames';
import { observer } from 'mobx-react';
import { QRCode } from 'react-qrcode-logo';
import { twMerge } from 'tailwind-merge';

import { useUserRefLink } from '~entities/user/lib';
import { isDefined } from '~shared/lib';
import { Skeleton } from '~shared/ui';

export interface RefQrProps {
  className?: string;
  qrHexColor: string;
  usernameColorClassName: string;
  sphereColorClassName: string;
}

const RefQrBase = forwardRef<HTMLDivElement, RefQrProps>(
  ({ className, qrHexColor, usernameColorClassName, sphereColorClassName }, ref) => {
    const userRefInfo = useUserRefLink();

    return (
      <div className={twMerge('relative flex flex-col items-center', className)} ref={ref}>
        {isDefined(userRefInfo) ? (
          <>
            <div className="flex flex-col items-center rounded-xl border border-border bg-background p-2 pb-[14px]">
              <QRCode
                style={{ transition: 'all .3s ease-in-out' }}
                fgColor={qrHexColor}
                bgColor="#1B1B2A"
                size={110}
                value={userRefInfo.refLink}
                qrStyle="squares"
                eyeRadius={0}
                removeQrCodeBehindLogo
              />

              {isDefined(userRefInfo.username) && (
                <p className={cn('mt-1 text-base font-bold transition-all', usernameColorClassName)}>
                  {userRefInfo.username}
                </p>
              )}
            </div>
            <div
              className={cn(
                'pointer-events-none absolute left-1/2 top-[-160px] z-[-1] size-[320px] -translate-x-1/2 mix-blend-overlay blur-xl transition-all',
                sphereColorClassName
              )}
            />
          </>
        ) : (
          <>
            <Skeleton className="size-[170px]" />
            <Skeleton className="mt-3 h-7 w-[180px]" />
          </>
        )}
      </div>
    );
  }
);
RefQrBase.displayName = 'RefQr';
const RefQr = observer(RefQrBase);

export { RefQr };
