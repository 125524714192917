import React from 'react';

import { isDefined } from '~shared/lib';
import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from '~shared/ui';

import { useToast } from '../lib';

export const Toaster: React.FC = () => {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, action, ...props }) {
        return (
          <Toast key={id} {...props}>
            <div className="grid w-full gap-1">
              {isDefined(title) && <ToastTitle>{title}</ToastTitle>}
              {isDefined(description) && <ToastDescription>{description}</ToastDescription>}
            </div>
            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
};
