import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useTonConnectUI } from '@tonconnect/ui-react';
import { useStore } from 'app/store';
import html2canvas from 'html2canvas-pro';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';
import TonWeb from 'tonweb';

import { STORIES_TASKS_TYPE_ID } from '~entities/task/model/payment-tasks-id.model';
import { useToast } from '~features/toaster/lib';
import { HomePage } from '~pages/home';
import {
  PaymentTaskSchema,
  UserInviteeTaskSchema,
  WidgetsTasksApiService,
  WidgetsTransactionsApiService,
  type ApiError,
  type UserTaskSchema,
} from '~shared/api/client';
import { isDefined } from '~shared/lib';
import {
  Amount,
  AnimatedCounter,
  Button,
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
} from '~shared/ui';

interface TaskDrawerProps {
  task: UserTaskSchema | UserInviteeTaskSchema | PaymentTaskSchema | null;
  setSelectedTask: (task: UserTaskSchema | UserInviteeTaskSchema | PaymentTaskSchema | null) => void;
  onComplete: (task: UserTaskSchema | UserInviteeTaskSchema | PaymentTaskSchema) => void;
}

export const TaskDrawer: React.FC<TaskDrawerProps> = observer(({ task, setSelectedTask, onComplete }) => {
  const {
    userStore: { user, openConnectWalletDrawer },
    projectStore: { project, getProject },
  } = useStore();

  const [tonConnectUI] = useTonConnectUI();

  const { toast } = useToast();

  const timerRef = useRef<NodeJS.Timer>();
  const [seconds, setSeconds] = useState<number | null>(null);
  const [isCompleteLoading, setIsCompleteLoading] = useState(false);
  const [isRequirementsFulfilled, setIsRequirementsFulfilled] = useState(false);

  const homePageRef = useRef<HTMLDivElement | null>(null);

  const isCompleted = useMemo(() => {
    return (
      (task as UserTaskSchema)?.is_completed ??
      (task as PaymentTaskSchema)?.state === PaymentTaskSchema.state.COMPLETED ??
      (task as UserInviteeTaskSchema)?.state === UserInviteeTaskSchema.state.COMPLETED
    );
  }, [task]);

  const taskType = useMemo(() => {
    if (isDefined((task as UserInviteeTaskSchema)?.count)) {
      return 'invitee';
    }

    if (isDefined((task as PaymentTaskSchema)?.to)) {
      return 'payment';
    }

    if (task?.type.id === STORIES_TASKS_TYPE_ID) {
      return 'stories';
    }

    return 'common';
  }, [task]);

  const handleOpenChange = useCallback(
    (newOpen: boolean) => {
      if (!newOpen) {
        setSelectedTask(null);
        clearInterval(timerRef.current);
        setSeconds(null);
        setIsRequirementsFulfilled(false);
      }
    },
    [setSelectedTask]
  );

  const handlePostStory = useCallback(async () => {
    try {
      if (isNil(homePageRef.current)) throw new Error();
      const canvas = await html2canvas(homePageRef.current, { scale: 4 });
      Telegram.WebView.postEvent('web_app_share_to_story', false, { media_url: canvas.toDataURL() });
      setIsRequirementsFulfilled(true);
    } catch (error) {
      console.error(error);
      toast({
        title:
          ((error as ApiError)?.body as { detail: { msg: string } })?.detail?.msg ??
          ((error as ApiError)?.body as { detail: Array<{ msg: string }> })?.detail?.[0]?.msg ??
          'Unknown error',
      });
    }
  }, [toast]);

  const handleCreatePayment = useCallback(async () => {
    try {
      if (isNil(project) || isNil(project.id) || isNil(user) || isNil(user.id)) return;

      if (
        isDefined(user) &&
        isDefined(user.id) &&
        isDefined(task) &&
        isDefined(task.id) &&
        isDefined(user.ton_wallet_address) &&
        tonConnectUI.connected
      ) {
        handleOpenChange(false);
        const txResponse = await tonConnectUI.sendTransaction(
          {
            messages: [
              {
                address: (task as PaymentTaskSchema).to.address,
                amount: TonWeb.utils.toNano((task as PaymentTaskSchema).to.amount).toString(),
              },
            ],
            from: tonConnectUI.account?.address,
            validUntil: Math.floor(Date.now() / 1000) + 360,
          },
          { returnStrategy: 'back' }
        );

        const bocCell = TonWeb.boc.Cell.oneFromBoc(TonWeb.utils.base64ToBytes(txResponse.boc));
        const txHash = TonWeb.utils.bytesToBase64(await bocCell.hash());
        await WidgetsTransactionsApiService.taskCaptureTransactionApiV2WidgetUsersUserIdTransactionsTaskCapturePost(
          user.id,
          {
            amount: (task as PaymentTaskSchema).to.amount,
            currency: (task as PaymentTaskSchema).to.currency,
            task_id: task.id,
            transaction_hash: txHash,
          }
        );
        await getProject(project.id, user.id);
        setSelectedTask(task);
        setIsRequirementsFulfilled(true);
      } else if (isNil(user?.ton_wallet_address) || !tonConnectUI.connected) {
        handleOpenChange(false);
        openConnectWalletDrawer();
      }
    } catch (error) {
      console.error(error);
      toast({ title: 'Unknown error' });
    }
  }, [
    toast,
    user,
    tonConnectUI,
    handleOpenChange,
    openConnectWalletDrawer,
    task,
    project,
    getProject,
    setSelectedTask,
  ]);

  const handleCompleteTask = useCallback(async () => {
    if (isNil(user) || isNil(user.id) || isNil(task) || isNil(task.id) || isNil(project) || isNil(project.id)) return;
    Telegram.WebApp.HapticFeedback.notificationOccurred('success');

    try {
      const taskResponse =
        await WidgetsTasksApiService.completeApiV2WidgetUsersUserIdProjectsProjectIdTasksTaskIdCompletedPost(
          task.id,
          user.id,
          project.id
        );
      onComplete(taskResponse);
    } catch (error) {
      toast({
        title:
          ((error as ApiError)?.body as { detail: { msg: string } })?.detail?.msg ??
          ((error as ApiError)?.body as { detail: Array<{ msg: string }> })?.detail?.[0]?.msg ??
          'Unknown error',
      });
      timerRef.current = setInterval(() => {
        setSeconds((prev) => (isNil(prev) ? 10 : prev - 1));
      }, 1000);
      console.error('error');
    }
  }, [user, project, task, toast, onComplete]);

  const handleTaskStart = useCallback(async () => {
    try {
      setIsCompleteLoading(true);

      switch (taskType) {
        case 'payment':
          if (isRequirementsFulfilled || (task as PaymentTaskSchema).state === PaymentTaskSchema.state.READY) {
            await handleCompleteTask();
          } else {
            await handleCreatePayment();
          }
          break;
        case 'stories':
          if (isRequirementsFulfilled) {
            await handleCompleteTask();
          } else {
            await handlePostStory();
          }
          break;
        case 'invitee':
          if ((task as UserInviteeTaskSchema).state === UserInviteeTaskSchema.state.READY) {
            await handleCompleteTask();
          }
          break;
        case 'common':
        default:
          await handleCompleteTask();
      }
    } catch (error) {
    } finally {
      setIsCompleteLoading(false);
    }
  }, [handleCompleteTask, handlePostStory, handleCreatePayment, isRequirementsFulfilled, task, taskType]);

  useEffect(() => {
    if (isDefined(seconds) && seconds <= 0) {
      clearInterval(timerRef.current);
      setSeconds(null);
    }
  }, [seconds, timerRef]);

  useEffect(() => {
    if (isDefined(task) && !isCompleted && taskType === 'common') {
      timerRef.current = setInterval(() => {
        setSeconds((prev) => (isNil(prev) ? 10 : prev - 1));
      }, 1000);
    }
  }, [task, isCompleted, taskType, timerRef]);

  return (
    <Drawer open={isDefined(task)} onOpenChange={handleOpenChange}>
      <DrawerContent>
        <DrawerHeader className="flex flex-col items-center">
          {isDefined(task) && isDefined(task.type.logo_url) && (
            <img
              className="mb-8 size-[80px] overflow-hidden rounded-full object-cover object-center"
              src={task.type.logo_url}
              alt=""
            />
          )}
          <DrawerTitle>{task?.name}</DrawerTitle>
          <DrawerDescription>{task?.description}</DrawerDescription>
        </DrawerHeader>

        <Amount
          className="mt-8 [&>*]:text-2xl [&>img]:mr-1 [&>img]:size-10"
          value={`+${task?.amount}`}
          logo={project?.logo_url}
        />

        {(isNil(seconds) || seconds <= 0) && isDefined(task) && !isCompleted && (
          <Button className="mt-8 h-[60px]" buttonColor="blue" isLoading={isCompleteLoading} onClick={handleTaskStart}>
            {taskType === 'common' && 'Check'}
            {(taskType === 'invitee' ||
              (task as PaymentTaskSchema).state === PaymentTaskSchema.state.READY ||
              isRequirementsFulfilled) &&
              'Claim'}
            {(taskType === 'payment' || taskType === 'stories') &&
              !isRequirementsFulfilled &&
              (task as PaymentTaskSchema).state !== PaymentTaskSchema.state.READY &&
              'Start'}
          </Button>
        )}

        {isDefined(seconds) && seconds > 0 && (
          <>
            <Button className="mt-8 h-[60px]" buttonColor="red" isLoading={isCompleteLoading}>
              <AnimatedCounter className="mr-[1ch] h-full" value={seconds.toString()} /> seconds
            </Button>
            <p className="absolute bottom-5 left-1/2 -translate-x-1/2 text-center text-2xs text-red">
              Activity not confirmed. Are you certain you completed this task?
            </p>
          </>
        )}

        <div className="h-0 overflow-hidden">
          <div className="h-[930px] w-[430px] bg-background [&_*]:!leading-[normal]" ref={homePageRef}>
            <HomePage asFrame />
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
});
