import React from 'react';

import * as ProgressPrimitive from '@radix-ui/react-progress';
import { twMerge } from 'tailwind-merge';

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>
>(({ className, value, ...props }, ref) => (
  <ProgressPrimitive.Root
    ref={ref}
    className={twMerge('relative h-[10px] w-full overflow-hidden rounded-full bg-progress', className)}
    {...props}
  >
    <ProgressPrimitive.Indicator
      className={twMerge(
        'size-full flex-1 rounded-full bg-primary transition-all',
        (value ?? 0) < 100 ? 'bg-yellow' : 'bg-green'
      )}
      style={{ transform: `translateX(-${100 - (value ?? 0)}%)` }}
    />
  </ProgressPrimitive.Root>
));
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
