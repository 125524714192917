import React, { useEffect } from 'react';

import { useStore } from 'app/store';
import { observer } from 'mobx-react';
import Gravatar from 'react-gravatar';
import { useParams } from 'react-router-dom';

import { PeopleIcon } from '~shared/assets/icons';
import { formatAmount, isDefined } from '~shared/lib';
import { Amount, Button, Page, Skeleton } from '~shared/ui';

import { useLevelStructure } from '../lib';

export const LevelFriendsPage: React.FC = observer(() => {
  const {
    projectStore: { project },
  } = useStore();
  const { level } = useParams();
  const { structure, structureCount, handleLoadMore, isStructureLoading } = useLevelStructure(level);

  useEffect(() => {
    window.Telegram?.WebApp?.BackButton?.show();

    return () => {
      window.Telegram?.WebApp?.BackButton?.hide();
    };
  }, []);

  return (
    <Page
      className="items-center"
      headerContent={
        <>
          <h1 className="mr-5 text-lg font-bold uppercase">{level} lvl</h1>
          {isDefined(structureCount) ? (
            <div className="flex items-center">
              <PeopleIcon className="mr-1 size-6 text-white/50" />
              <span className="text-xs font-semibold">{structureCount.count}</span>
            </div>
          ) : (
            <Skeleton className="h-6 w-10" />
          )}
        </>
      }
    >
      <div className="flex w-full flex-col space-y-2">
        {isDefined(structure) &&
          structure.results.map((item) => (
            <div className="flex items-center rounded-[10px] bg-white/10 p-3 backdrop-blur-lg" key={item.id}>
              <Gravatar className="mr-4 rounded-full" email={item.id} size={46} />
              <div className="flex flex-col">
                <span className="text-md">{item.username ?? '—'}</span>
                <Amount
                  className="justify-start [&>*]:text-base [&>img]:mr-1 [&>img]:size-5"
                  value={formatAmount(parseFloat(item.balance ?? '0'))}
                  logo={project?.logo_url}
                />
              </div>
            </div>
          ))}
        {isStructureLoading &&
          Array(8)
            .fill(null)
            .map((_, index) => <Skeleton key={index} className="h-[70px] w-full" />)}
        {isDefined(structure) && structure.count === 0 && <p className="text-center text-red">No friends yet</p>}
        {isDefined(structure) && structure.results.length < structure.count && (
          <Button onClick={handleLoadMore} isLoading={isStructureLoading}>
            Load more
          </Button>
        )}
      </div>
    </Page>
  );
});
