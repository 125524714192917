import React, { useCallback, useState } from 'react';

import { useTonConnectUI } from '@tonconnect/ui-react';
import { useStore } from 'app/store';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';

import { ClipboardCopy } from '~features/clipboard-copy';
import { BeggarIcon, CopyIcon, DeleteIcon, WalletIcon } from '~shared/assets/icons';
import { isDefined } from '~shared/lib';
import { Button, Page, Spinner } from '~shared/ui';

export const WalletPage: React.FC = observer(() => {
  const [tonConnectUI] = useTonConnectUI();

  const [isDisconnectLoading, setIsDisconnectLoading] = useState(false);

  const {
    userStore: { user, openConnectWalletDrawer, updateUserWallet },
  } = useStore();

  const handleDisconnect = useCallback(async () => {
    if (isNil(user) || isNil(user.id)) return;

    try {
      setIsDisconnectLoading(true);
      await tonConnectUI.disconnect();
      await updateUserWallet(user.id, null);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDisconnectLoading(false);
    }
  }, [tonConnectUI, user, updateUserWallet]);

  return (
    <Page className="flex flex-col space-y-3" headerContent={<h1 className="text-lg font-bold uppercase">Wallet</h1>}>
      <div className="flex flex-col space-y-6 rounded-xl bg-white/10 p-6 backdrop-blur-lg">
        <div className="flex w-full items-start justify-between space-x-7">
          <div className="flex flex-col space-y-2">
            <h5 className="font-bold">TON Wallet</h5>
            <p>Link your wallet to unlock future rewards.</p>
          </div>

          <div className="rounded-full bg-white/[0.08] p-[10px] text-white">
            <WalletIcon className="size-6" />
          </div>
        </div>

        {isDefined(user) && isDefined(user.ton_wallet_address) ? (
          <div className="flex items-center space-x-3">
            <Button className="rounded-xl bg-background p-3 text-white" variant="wrapper" onClick={handleDisconnect}>
              {isDisconnectLoading ? <Spinner className="size-6" /> : <DeleteIcon className="size-6" />}
            </Button>

            <div className="flex size-full min-w-0 items-center rounded-xl bg-background px-3 py-1 text-white">
              <div className="flex w-full min-w-0 flex-col text-xs">
                <span className="text-white/50">Wallet</span>
                <span className="w-full min-w-0 overflow-hidden text-ellipsis">{user.ton_wallet_address}</span>
              </div>

              <ClipboardCopy variant="wrapper" size="wrapper" value={user.ton_wallet_address}>
                <CopyIcon className="size-6" />
              </ClipboardCopy>
            </div>
          </div>
        ) : (
          <Button
            className="w-min rounded-xl px-8 py-2 text-base font-normal"
            buttonColor="blue"
            onClick={openConnectWalletDrawer}
          >
            Connect TON Wallet
          </Button>
        )}
      </div>

      <div className="flex flex-col space-y-6 rounded-xl bg-white/10 p-6 backdrop-blur-lg">
        <div className="flex w-full items-start justify-between space-x-7">
          <div className="flex flex-col space-y-2">
            <h5 className="font-bold">Staking</h5>
            <p>Secure a portion of your digital assets for a set period to gain rewards.</p>
          </div>

          <div className="rounded-full bg-white/[0.08] p-[10px] text-white">
            <BeggarIcon className="size-6" />
          </div>
        </div>

        <Button className="w-min rounded-xl px-8 py-2 text-base font-normal" buttonColor="blue" disabled>
          Stake
        </Button>
      </div>
    </Page>
  );
});
