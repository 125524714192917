import { useCallback, useEffect, useState } from 'react';

import { useStore } from 'app/store';

import {
  type GetInviteeTasksListResponse,
  WidgetsTasksApiService,
  type GetUserTasksListResponse,
  type GetPaymentTasksListResponse,
  type UserTaskSchema,
  type PaymentTaskSchema,
} from '~shared/api/client';
import { isDefined } from '~shared/lib';

export type TasksInfo = {
  static: GetUserTasksListResponse | null;
  active: { count: number; results: Array<UserTaskSchema | PaymentTaskSchema> } | null;
  invitee: GetInviteeTasksListResponse | null;
};

export const useTasks = (): TasksInfo => {
  const {
    userStore: { user },
    projectStore: { project },
  } = useStore();
  const [staticTasks, setStaticTasks] = useState<GetUserTasksListResponse | null>(null);
  const [activeTasks, setActiveTasks] = useState<GetUserTasksListResponse | null>(null);
  const [inviteeTasks, setInviteeTasks] = useState<GetInviteeTasksListResponse | null>(null);
  const [paymentTasks, setPaymentTasks] = useState<GetPaymentTasksListResponse | null>(null);

  const getStaticTasks = useCallback(async (projectId: string, userId: string) => {
    try {
      const staticTasksResponse =
        await WidgetsTasksApiService.getStaticApiV2WidgetUsersUserIdProjectsProjectIdStaticTasksGet(userId, projectId);
      setStaticTasks(staticTasksResponse);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getActiveTasks = useCallback(async (projectId: string, userId: string) => {
    try {
      const activeTasksResponse =
        await WidgetsTasksApiService.getActiveApiV2WidgetUsersUserIdProjectsProjectIdActiveTasksGet(userId, projectId);
      setActiveTasks(activeTasksResponse);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getPaymentTasks = useCallback(async (userId: string) => {
    try {
      const paymentTasksReponse =
        await WidgetsTasksApiService.getPaymentsApiV2WidgetUsersUserIdPaymentsTasksGet(userId);
      setPaymentTasks(paymentTasksReponse);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getInviteeTasks = useCallback(async (userId: string) => {
    try {
      const inviteeTasksResponse = await WidgetsTasksApiService.getInviteeApiV2WidgetUsersUserIdInviteeTasksGet(userId);
      setInviteeTasks(inviteeTasksResponse);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (isDefined(user) && isDefined(user.id) && isDefined(project) && isDefined(project.id)) {
      void getStaticTasks(project.id, user.id);
      void getActiveTasks(project.id, user.id);
      void getInviteeTasks(user.id);
      void getPaymentTasks(user.id);
    }
  }, [getStaticTasks, getActiveTasks, getPaymentTasks, getInviteeTasks, user, project]);

  return {
    static: staticTasks,
    active:
      isDefined(activeTasks) && isDefined(paymentTasks)
        ? { results: [...paymentTasks.results, ...activeTasks.results], count: paymentTasks.count + activeTasks.count }
        : null,
    invitee: inviteeTasks,
  };
};
