import React, { forwardRef, useEffect } from 'react';

import { useStore } from 'app/store';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';

import { BoostPageTab } from '~entities/boost';
import { useLatestJob } from '~entities/job';
import { AirDropCountdown } from '~features/airdrop-countdown';
import { BoostButton } from '~features/boost-button';
import { UserBalance } from '~features/user-balance';
import { isDefined } from '~shared/lib';
import { Hedgehog, Page, Skeleton } from '~shared/ui';
import { CoinStackAnimation } from '~widgets/coin-stack-animation';
import { JobStatusButton } from '~widgets/job-status-button';

import { JOB_STATUS_MAP } from '../model';

interface HomePageProps {
  asFrame?: boolean;
}

const HomePage = forwardRef<HTMLDivElement, HomePageProps>(({ asFrame = false }, ref) => {
  const {
    jobStore: { getLatestJob },
    projectStore: { project },
    boostsStore: { isAutoFarmingWelcomeDrawerShown },
    dailyBonusesStore: { dailyBonuses, isDailyBonusDrawerShown },
    userStore: { user, subscriptionStatus, openSubscriptionCheckDrawer, getSubscriptionStatus },
  } = useStore();
  const { latestJob, loaded } = useLatestJob();

  const colorScheme = isDefined(latestJob)
    ? JOB_STATUS_MAP[latestJob.status]
    : loaded
      ? JOB_STATUS_MAP.COMPLETED
      : null;

  useEffect(() => {
    if (isDefined(project) && isDefined(user)) {
      void getLatestJob(project.id ?? '', user.id ?? '');
    }
  }, [project, user, getLatestJob]);

  useEffect(() => {
    void getSubscriptionStatus();
  }, [getSubscriptionStatus]);

  useEffect(() => {
    if (
      isDefined(subscriptionStatus) &&
      ((isDefined(dailyBonuses) && !dailyBonuses.can_claim) || isDailyBonusDrawerShown) &&
      (isAutoFarmingWelcomeDrawerShown || (isDefined(latestJob) && isNil(latestJob.auto_farming_purchased_id))) &&
      !subscriptionStatus.result &&
      !asFrame
    ) {
      openSubscriptionCheckDrawer();
    }
  }, [
    subscriptionStatus,
    openSubscriptionCheckDrawer,
    dailyBonuses,
    isDailyBonusDrawerShown,
    asFrame,
    isAutoFarmingWelcomeDrawerShown,
    latestJob,
  ]);

  return (
    <Page className="items-center" background={colorScheme?.colorName ?? 'multicolor'} ref={ref}>
      {isDefined(project) && project.is_root ? (
        <Hedgehog className="mt-auto w-[108px]" color={colorScheme?.colorName ?? 'white'} withText />
      ) : isDefined(project) ? (
        <img className="mt-auto w-[120px] overflow-hidden rounded-full" src={project.logo_url ?? ''} alt="" />
      ) : (
        <Skeleton className="mt-auto h-[100px] w-[120px] shrink-0 rounded-full" />
      )}

      {isDefined(project) && !project.is_root && <h1 className="text-base font-normal uppercase">{project?.name}</h1>}

      <UserBalance className="mt-6" />

      <BoostButton className="mt-4" tab={BoostPageTab.Farming}>
        Double your rewards
      </BoostButton>

      <AirDropCountdown className="mt-4" project={project} />

      <CoinStackAnimation className="mb-4 mt-6" />

      <JobStatusButton className="mt-auto" />
    </Page>
  );
});
HomePage.displayName = 'HomePage';
const ObservedHomePage = observer(HomePage);

export { ObservedHomePage as HomePage };
