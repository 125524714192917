import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useStore } from 'app/store';
import { motion, useMotionValue } from 'framer-motion';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';

import { AirDropCountdown } from '~features/airdrop-countdown';
import { AirDropListItem } from '~features/airdrop-list-item';
import { ProjectLink } from '~features/project-link';
import { WidgetsAirDropsApiService, type GetAirdropResponse, type UserProjectSchema } from '~shared/api/client';
import { isDefined, useScreenDimensions } from '~shared/lib';
import { Page, Skeleton, Tabs, TabsContent, TabsList, TabsTrigger } from '~shared/ui';
import { AirdropDescriptionDrawer } from '~widgets/airdrop-description-drawer';
import { ProjectDescriptionDrawer } from '~widgets/project-description-drawer';
import { ProjectDrawer } from '~widgets/project-drawer';
import { XAccountDrawer } from '~widgets/x-account-drawer';

import { AirDropTabs, AirDropTabType } from '../model';

export const ProjectsPage: React.FC = observer(() => {
  const {
    jobStore: { resetJob },
    projectStore: { project, getProjects, rootProject },
    userStore: { user },
  } = useStore();
  const [isXAccountDrawerOpen, setIsXAccountDrawerOpen] = useState(false);

  const [selectedAirDrop, setSelectedAirDrop] = useState<GetAirdropResponse | null>(null);
  const [aridrops, setAirdrops] = useState<{
    active: GetAirdropResponse[] | null;
    planned: GetAirdropResponse[] | null;
    completed: GetAirdropResponse[] | null;
  }>({
    active: null,
    planned: null,
    completed: null,
  });
  const [selectedAirDropTab, setSelectedAirDropTab] = useState<AirDropTabType>(AirDropTabType.Active);
  const linksRefs = useRef<HTMLButtonElement[]>([]);
  const indicatorLeft = useMotionValue(0);
  const indicatorWidth = useMotionValue(0);
  const { width: screenWidth } = useScreenDimensions();

  const [selectedProjectDrawerProject, setSelectedProjectDrawerProject] = useState<UserProjectSchema | null>(null);
  const [selectedProjectDescriptionProject, setSelectedProjectDescriptionProject] = useState<UserProjectSchema | null>(
    null
  );

  const getAirdrops = useCallback(async () => {
    try {
      const airdropsResponse = await WidgetsAirDropsApiService.getAirdropsListApiV2WidgetAirdropsGet(100, 0);
      const currentTime = Math.floor(new Date().getTime() / 1000);
      setAirdrops({
        active: airdropsResponse.results.filter((airdrop) => {
          return currentTime <= airdrop.end_at && currentTime >= airdrop.start_at;
        }),
        planned: airdropsResponse.results.filter((airdrop) => {
          return currentTime < airdrop.start_at;
        }),
        completed: airdropsResponse.results.filter((airdrop) => {
          return currentTime > airdrop.end_at;
        }),
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleAirdropTypeChange = useCallback((newTab: string) => {
    setSelectedAirDropTab(newTab as AirDropTabType);
  }, []);

  useEffect(() => {
    if (isDefined(user)) {
      void getProjects(user.id ?? '');
    }
  }, [user, getProjects]);

  useEffect(() => {
    resetJob();
  }, [resetJob]);

  useEffect(() => {
    void getAirdrops();
  }, [getAirdrops]);

  useEffect(() => {
    const tabIndex = AirDropTabs.findIndex(({ type }) => type === selectedAirDropTab);

    if (tabIndex !== -1 && tabIndex < linksRefs.current.length) {
      indicatorLeft.set(linksRefs.current[tabIndex].offsetLeft + 10);
      indicatorWidth.set(linksRefs.current[tabIndex].clientWidth - 20);
    } else {
      indicatorLeft.set(0);
      indicatorWidth.set(0);
    }
  }, [selectedAirDropTab, indicatorLeft, indicatorWidth, screenWidth]);

  const selectedAirdrops = aridrops[selectedAirDropTab];

  return (
    <>
      <Page background="multicolor">
        <h1 className="text-center text-lg font-bold">AirDrops</h1>

        <AirDropCountdown className="mt-10" project={project} />

        <Tabs className="mt-8 flex w-full flex-col" value={selectedAirDropTab} onValueChange={handleAirdropTypeChange}>
          <TabsList className="relative">
            {AirDropTabs.map((tab, index) => (
              <TabsTrigger
                value={tab.type}
                key={tab.type}
                ref={(ref) => {
                  if (isDefined(ref)) {
                    linksRefs.current[index] = ref;
                  }
                }}
              >
                {tab.label}
              </TabsTrigger>
            ))}
            <motion.div
              className="absolute bottom-0 h-px bg-blue transition-all"
              style={{ left: indicatorLeft, width: indicatorWidth }}
            />
          </TabsList>

          {AirDropTabs.map((tab) => (
            <TabsContent className="mt-8 flex w-full flex-col space-y-5" value={tab.type} key={tab.type}>
              <h2 className="text-left text-md font-bold">{tab.label}</h2>

              <div className="flex w-full flex-col space-y-2">
                {isDefined(rootProject) && selectedAirDropTab === AirDropTabType.Active && (
                  <ProjectLink project={rootProject} />
                )}
                {isNil(selectedAirdrops) && <Skeleton className="h-[72px] w-full" />}
                {isDefined(selectedAirdrops) &&
                  selectedAirDropTab !== AirDropTabType.Active &&
                  selectedAirdrops.length === 0 && <p>No airdrops for now</p>}
                {isDefined(selectedAirdrops) &&
                  selectedAirdrops.map((airdrop) => (
                    <AirDropListItem
                      key={airdrop.id}
                      airdrop={airdrop}
                      onSelect={() => {
                        setSelectedAirDrop(airdrop);
                      }}
                    />
                  ))}
              </div>
            </TabsContent>
          ))}
        </Tabs>
      </Page>

      <ProjectDrawer
        project={selectedProjectDrawerProject}
        onClose={() => {
          setSelectedProjectDrawerProject(null);
        }}
      />

      <ProjectDescriptionDrawer
        project={selectedProjectDescriptionProject}
        onClose={() => {
          setSelectedProjectDescriptionProject(null);
        }}
      />

      <AirdropDescriptionDrawer
        airdrop={selectedAirDrop}
        onClose={() => {
          setSelectedAirDrop(null);
        }}
      />

      <XAccountDrawer
        isOpen={isXAccountDrawerOpen}
        onClose={(newOpen) => {
          setIsXAccountDrawerOpen(newOpen);
        }}
      />
    </>
  );
});
