import type React from 'react';
import { useCallback } from 'react';

import { useStore } from 'app/store';
import { observer } from 'mobx-react';

import { UserInviteeTaskSchema } from '~shared/api/client';
import { CheckIcon } from '~shared/assets/icons';
import { formatAmount } from '~shared/lib';
import { Amount, Button } from '~shared/ui';

interface InviteeTaskListItemProps {
  task: UserInviteeTaskSchema;
  onSelect: (event: React.MouseEvent, task: UserInviteeTaskSchema) => void;
}

export const InviteeTaskListItem: React.FC<InviteeTaskListItemProps> = observer(({ task, onSelect }) => {
  const {
    projectStore: { project },
    userStore: { openShareDrawer },
  } = useStore();

  const handleShareDrawerOpen = useCallback(() => {
    Telegram.WebApp.HapticFeedback.notificationOccurred('success');
    openShareDrawer();
  }, [openShareDrawer]);

  const handleTaskSelect = useCallback(
    (event: React.MouseEvent) => {
      Telegram.WebApp.HapticFeedback.notificationOccurred('success');
      onSelect(event, task);
    },
    [onSelect, task]
  );

  return (
    <div className="flex w-full items-center rounded-[10px] bg-white/10 p-3 backdrop-blur-lg disabled:opacity-100">
      <div className="mr-3 flex flex-col">
        <Amount
          className="justify-start [&>*]:text-base [&>img]:mr-1 [&>img]:size-5"
          value={`+${formatAmount(parseFloat(task.amount))}`}
          logo={project?.logo_url}
        />
        <p className="mt-3 text-base font-normal">
          Invite <span className="rounded-full bg-blue px-2 py-[2px]">+{task.count}</span> friends
        </p>
      </div>
      {task.state === UserInviteeTaskSchema.state.COMPLETED && (
        <Button
          variant="wrapper"
          size="wrapper"
          className="ml-auto flex items-center rounded-xl border border-[#8FF3D01A] px-5 py-2 text-mint"
          onClick={handleTaskSelect}
        >
          <CheckIcon className="mr-2 size-[18px]" />
          <span>Done</span>
        </Button>
      )}
      {task.state === UserInviteeTaskSchema.state.READY && (
        <Button
          className="ml-auto rounded-xl px-5 py-2 text-base font-normal"
          buttonColor="green"
          onClick={handleTaskSelect}
        >
          Claim
        </Button>
      )}
      {task.state === UserInviteeTaskSchema.state.NOT_READY && (
        <Button
          className="ml-auto rounded-xl px-5 py-2 text-base font-normal"
          buttonColor="blue"
          onClick={handleShareDrawerOpen}
        >
          Invite
        </Button>
      )}
    </div>
  );
});
