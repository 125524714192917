/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* eslint-disable */
export type UpdateBalanceSchema = {
  telegram_id: number;
  amount: number | string;
  direction: UpdateBalanceSchema.direction;
  scode: string;
};
export namespace UpdateBalanceSchema {
  export enum direction {
    IN = 'in',
    OUT = 'out',
  }
}
