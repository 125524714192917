import { useEffect, useRef, useState } from 'react';

import { useStore } from 'app/store';
import * as dateFns from 'date-fns';

import { type PaymentProductSchema } from '~shared/api/client';
import { isDefined } from '~shared/lib';

export const useActiveBoostTimer = (
  boost: PaymentProductSchema | null,
  enabledBoostEndsAt?: string | null,
  enabledBoostId?: string | null
): {
  isActive: boolean;
  timerValues: {
    days: string;
    hours: string;
    minutes: string;
  };
} => {
  const {
    userStore: { user },
    projectStore: { project, getProject },
  } = useStore();

  const timerRef = useRef<NodeJS.Timer>();

  const [minutesRemaining, setMinutesRemaining] = useState(0);

  useEffect(() => {
    clearInterval(timerRef.current);
    const handleTimerUpdate = (): void => {
      const secondsRemaning = dateFns.differenceInSeconds(
        enabledBoostId === boost?.id && isDefined(enabledBoostEndsAt) ? new Date(`${enabledBoostEndsAt}Z`) : new Date(),
        new Date()
      );

      setMinutesRemaining(Math.trunc(secondsRemaning / 60));
    };

    handleTimerUpdate();
    timerRef.current = setInterval(handleTimerUpdate, 30000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [user, boost, enabledBoostEndsAt, enabledBoostId, getProject, project]);

  return {
    isActive: minutesRemaining > 0,
    timerValues: {
      days: Math.trunc(minutesRemaining / 60 / 24).toString(),
      hours: Math.trunc((minutesRemaining % 1440) / 60).toString(),
      minutes: (minutesRemaining % 60).toString(),
    },
  };
};
