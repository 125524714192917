import React, { useEffect, useState } from 'react';

import { useStore } from 'app/store';
import cn from 'classnames';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import {
  AutoFarmingBoostCard,
  BOOST_PAGE_TABS_LIST,
  BoostPageTab,
  DailyRewardBoostCard,
  FarmingBoostCard,
  TopBoostCard,
} from '~entities/boost';
import { isDefined } from '~shared/lib';
import { Button, Page, Skeleton } from '~shared/ui';

export const BoostPage: React.FC = observer(() => {
  const {
    userStore: { user },
    projectStore: { project },
    boostsStore: { boosts, getBoosts },
  } = useStore();

  const { state } = useLocation();

  const [selectedTab, setSelectedTab] = useState<BoostPageTab>((state?.tab as BoostPageTab) ?? BoostPageTab.Farming);

  useEffect(() => {
    if (isDefined(user) && isDefined(user.id) && isDefined(project) && isDefined(project.id)) {
      void getBoosts(user.id, project.id);
    }
  }, [getBoosts, user, project]);

  return (
    <Page className="px-0" headerContent={<h1 className="text-lg font-bold uppercase">Boost</h1>}>
      <div className="no-scrollbar flex w-full shrink-0 items-center overflow-auto px-6">
        {BOOST_PAGE_TABS_LIST.map((tab, index) => (
          <Button
            className={cn('rounded-lg px-3 py-1 text-sm text-white/70 shadow-blue', {
              'shadow-[inset_0_0_0_1px] text-white': selectedTab === tab.value,
              'ml-auto': index === 0,
              'mr-auto': index === BOOST_PAGE_TABS_LIST.length - 1,
            })}
            variant="wrapper"
            key={tab.value}
            onClick={() => {
              setSelectedTab(tab.value);
            }}
          >
            {tab.label}
          </Button>
        ))}
      </div>

      <div className="mt-8 grid grid-cols-2 gap-[10px] px-6">
        {isNil(boosts) &&
          Array(8)
            .fill(null)
            .map((_, index) => <Skeleton className="h-[240px] w-full" key={index} />)}

        {selectedTab === BoostPageTab.Top &&
          isDefined(boosts) &&
          boosts.top.map((boost, index) => (
            <TopBoostCard
              className={cn({ 'col-span-2': index === boosts.top.length - 1 && index % 2 === 0 })}
              boost={boost}
              key={boost.id}
            />
          ))}

        {selectedTab === BoostPageTab.Farming &&
          isDefined(boosts) &&
          boosts.farming.map((boost, index) => (
            <FarmingBoostCard
              className={cn({ 'col-span-2': index === boosts.farming.length - 1 && index % 2 === 0 })}
              boost={boost}
              key={boost.id}
            />
          ))}

        {selectedTab === BoostPageTab.AutoFarming &&
          boosts?.auto_farming.map((boost) => (
            <AutoFarmingBoostCard className="col-span-2" boost={boost} key={boost.id} />
          ))}

        {selectedTab === BoostPageTab.DailyReward &&
          isDefined(boosts) &&
          boosts.daily_reward.map((boost, index) => (
            <DailyRewardBoostCard className="col-span-2" index={index} boost={boost} key={boost.id} />
          ))}
      </div>
    </Page>
  );
});
