import type React from 'react';
import { useCallback } from 'react';

import { useStore } from 'app/store';
import { observer } from 'mobx-react';

import { subscribeDrawerHedgehogImage } from '~shared/assets/images/hedgehogs';
import { Button, Drawer, DrawerContent, DrawerDescription, DrawerFooter, DrawerHeader, DrawerTitle } from '~shared/ui';

export const SubscriptionCheckDrawer: React.FC = observer(() => {
  const {
    userStore: { isSubscriptionStatusDrawerOpen, closeSubscriptionCheckDrawer },
  } = useStore();

  const handleOpenChange = useCallback(
    (newOpen: boolean) => {
      if (!newOpen) {
        closeSubscriptionCheckDrawer();
      }
    },
    [closeSubscriptionCheckDrawer]
  );

  return (
    <Drawer open={isSubscriptionStatusDrawerOpen} onOpenChange={handleOpenChange}>
      <DrawerContent hideCloseButton>
        <DrawerHeader className="mb-0 flex flex-col items-center">
          <img className="size-[112px]" src={subscribeDrawerHedgehogImage} alt="" />
          <DrawerTitle className="mt-3">DUO Airdrop</DrawerTitle>
          <DrawerDescription className="mt-4 text-base text-white">
            Complete all tasks during
            <br />
            the airdrop period for a chance to win
          </DrawerDescription>
          <span className="mt-4 text-2xl font-bold">+300$</span>
        </DrawerHeader>

        <DrawerFooter className="mt-8 gap-4">
          <Button className="h-[60px]" buttonColor="blue" onClick={closeSubscriptionCheckDrawer} asChild>
            <a href="https://t.me/graphdex" target="_blank" rel="noreferrer">
              Subscribe
            </a>
          </Button>
          <p className="text-center text-sm">
            Also subscribe to our Telegram for updates and exclusive content. Don’t miss out—join us now!
          </p>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
});
