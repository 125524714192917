import React, { useEffect } from 'react';

import { useStore } from 'app/store';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useUserWallet } from '~entities/user';
import { NavPanel } from '~features/nav-panel';
import { StarsBg } from '~features/stars-bg';
import { isDefined } from '~shared/lib';
import { AutoFarmingBoostActivatedDrawer } from '~widgets/auto-farming-boost-activated-drawer';
import { AutoFarmingWelcomeDrawer } from '~widgets/auto-farming-welcome-drawer';
import { ConnectWalletDrawer } from '~widgets/connect-wallet-drawer';
import { DailyBonusDrawer } from '~widgets/daily-bonus-drawer';
import { PurchaseGameLivesDrawer } from '~widgets/purchase-game-lives-drawer';
import { ShareDrawer } from '~widgets/share-drawer';
import { SubscriptionCheckDrawer } from '~widgets/subscription-check-drawer';

interface LayoutProps {
  withNavigation?: boolean;
}

export const Layout: React.FC<LayoutProps> = observer(({ withNavigation = true }) => {
  const {
    jobStore: { latestJob },
    boostsStore: { openAutoFarmingWelcomeDrawer, isAutoFarmingWelcomeDrawerShown },
    userStore: { getUser, user },
    dailyBonusesStore: { getDailyBonuses, openDailyBonusDrawer, dailyBonuses },
    projectStore: { selectProject, project, rootProject, getProjects },
  } = useStore();
  const navigate = useNavigate();

  useUserWallet();

  useEffect(() => {
    void getUser({ withIp: true });
  }, [getUser]);

  useEffect(() => {
    window.Telegram?.WebApp?.BackButton?.onClick(() => {
      navigate(-1);
    });
  }, [navigate]);

  useEffect(() => {
    if (isDefined(user)) {
      void getProjects(user.id ?? '');
    }
  }, [getProjects, user]);

  useEffect(() => {
    if (isDefined(rootProject) && isNil(project)) {
      selectProject(rootProject);
    }
  }, [rootProject, project, selectProject]);

  useEffect(() => {
    if (isDefined(user) && isDefined(user.id)) {
      void getDailyBonuses(user.id);
    }
  }, [user, getDailyBonuses]);

  useEffect(() => {
    if (
      isDefined(dailyBonuses) &&
      dailyBonuses.can_claim &&
      (isAutoFarmingWelcomeDrawerShown || (isDefined(latestJob) && isNil(latestJob.auto_farming_purchased_id)))
    ) {
      openDailyBonusDrawer();
    }
  }, [dailyBonuses, openDailyBonusDrawer, latestJob, isAutoFarmingWelcomeDrawerShown]);

  useEffect(() => {
    if (isDefined(latestJob) && isDefined(latestJob.auto_farming_purchased_id) && !isAutoFarmingWelcomeDrawerShown) {
      openAutoFarmingWelcomeDrawer();
    }
  }, [latestJob, openAutoFarmingWelcomeDrawer, isAutoFarmingWelcomeDrawerShown]);

  return (
    <div className="flex h-full flex-col">
      <Outlet />

      {withNavigation && <NavPanel />}

      <StarsBg className="-z-10" />
      <DailyBonusDrawer />
      <SubscriptionCheckDrawer />
      <ShareDrawer />
      <ConnectWalletDrawer />
      <PurchaseGameLivesDrawer />
      <AutoFarmingBoostActivatedDrawer />
      <AutoFarmingWelcomeDrawer />
    </div>
  );
});
