import React from 'react';

import { observer } from 'mobx-react';

import { type GetAirdropResponse } from '~shared/api/client';
import { ArrowRightIcon } from '~shared/assets/icons/arrows';
import { Button } from '~shared/ui';

interface AirDropListItemProps {
  airdrop: GetAirdropResponse;
  onSelect: () => void;
}

export const AirDropListItem: React.FC<AirDropListItemProps> = observer(({ airdrop, onSelect }) => {
  return (
    <Button
      className="flex h-[72px] w-full overflow-hidden rounded-[10px] bg-white/10 backdrop-blur-lg"
      onClick={onSelect}
      variant="wrapper"
      size="wrapper"
    >
      <div className="flex w-full items-center rounded-[10px] p-3">
        <img className="mr-3 size-10 shrink-0 overflow-hidden rounded-full" src={airdrop.image_url ?? ''} alt="" />

        <div className="mr-auto flex flex-col items-start">
          <span>{airdrop.title}</span>
        </div>
      </div>

      <div className="ml-auto flex h-full items-center justify-center bg-blue p-7">
        <ArrowRightIcon className="size-6 text-white" />
      </div>
    </Button>
  );
});
