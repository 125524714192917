import { useCallback, useState } from 'react';

import { useStore } from 'app/store';
import { isNil } from 'lodash';

import { type PaymentProductSchema } from '~shared/api/client';

export const usePurchaseBoost = (
  boost: PaymentProductSchema,
  callback?: () => void
): {
  handlePurchaseBoost: () => Promise<void>;
  isBoostLoading: boolean;
  isBoostsDisabled: boolean;
} => {
  const {
    userStore: { user, getUser },
    projectStore: { project, getProject },
    jobStore: { getLatestJob },
    boostsStore: { purchaseBoost, loading },
  } = useStore();

  const [isBoostLoading, setIsBoostLoading] = useState(false);

  const purchaseCallback = useCallback(async () => {
    if (isNil(user) || isNil(user.id) || isNil(project) || isNil(project.id)) return;
    await getUser();
    await getProject(project.id, user.id);
    await getLatestJob(project.id, user.id);
    callback?.();
  }, [getProject, getUser, getLatestJob, user, project, callback]);

  const handlePurchaseBoost = useCallback(async () => {
    if (isNil(user) || isNil(user.id) || isNil(project) || isNil(project.id)) return;
    setIsBoostLoading(true);
    await purchaseBoost(
      user.id,
      project.id,
      { id: boost.id, description: boost.description, title: boost.title },
      purchaseCallback
    );
    setIsBoostLoading(false);
  }, [user, project, purchaseBoost, boost, purchaseCallback]);

  const canBuy: boolean = boost.can_buy ?? true;

  return {
    handlePurchaseBoost,
    isBoostLoading,
    isBoostsDisabled: loading.purchase || !canBuy,
  };
};
