/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* eslint-disable */
export enum ProjectNetwork {
  ETH = 'ETH',
  BSC = 'BSC',
  TRON = 'TRON',
  TON = 'TON',
  SOL = 'SOL',
}
