import type React from 'react';
import { useCallback, useState } from 'react';

import { InfoIcon } from '~shared/assets/icons';
import { Button, Checkbox, Drawer, DrawerContent, DrawerHeader, DrawerTitle } from '~shared/ui';

interface TaskAgreementDrawerProps {
  isOpen: boolean;
  onOpenChange: (newOpen: boolean) => void;
}

export const TaskAgreementDrawer: React.FC<TaskAgreementDrawerProps> = ({ isOpen, onOpenChange }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleStart = useCallback(() => {
    onOpenChange(false);
    setIsChecked(false);
    sessionStorage.setItem('taskAgreement', 'true');
  }, [onOpenChange]);

  return (
    <Drawer open={isOpen}>
      <DrawerContent hideCloseButton>
        <DrawerHeader className="flex flex-col items-center">
          <InfoIcon className="size-[80px] text-white/80" />
          <DrawerTitle className="mt-8">DUO Airdrop</DrawerTitle>
        </DrawerHeader>

        <div className="mt-8 flex items-start space-x-3">
          <Checkbox
            id="taskAgreement"
            checked={isChecked}
            onCheckedChange={(checked) => {
              setIsChecked(checked as boolean);
            }}
          />
          <label
            htmlFor="taskAgreement"
            className="leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            We will check if tasks are completed correctly. Any attempts to cheat may result in losing the{' '}
            <span className="text-destructive">$300</span> airdrop reward. Please complete tasks honestly. Good luck!
          </label>
        </div>

        <Button className="mt-8 h-[60px]" buttonColor="blue" disabled={!isChecked} onClick={handleStart}>
          Start
        </Button>
      </DrawerContent>
    </Drawer>
  );
};
